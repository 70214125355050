import React, { useState } from 'react'
import Tags1 from '../CIOnboarding/Tags1'
import ResourceTagInput from './ResourceTagInput'
import { Button } from '@mui/material';
import {
    Box,
    TextField,
    Typography,
    Card,
    CardContent,
    CardActions,
    Grid,
    Chip,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
  } from "@mui/material";

function ResourceTag({ data }) {
    const [selectedItems, setSelectedItems] = useState([]);


    const handleTagClick = async () => {
      const requestBody = {
          data: data,
          selectedItems: selectedItems
      };

      try {
          const response = await fetch( `${
            process.env.REACT_APP_BACKEND_URI
          }/tagresources`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
              body: JSON.stringify(requestBody)
          });

          if (!response.ok) {
              throw new Error('Network response was not ok');
          }

          const responseData = await response.json();
          console.log('Success:', responseData);
      } catch (error) {
          console.error('Error:', error);
      }
  };

  return (
    <div>
      <div className="server-info-item">
      <p className="server-info-label">Select an application</p>
         <ResourceTagInput
                               type="text"
                               name="u_used_by_business_unit"
                               value={""}
                               disabled={false}
                               selectedItems={selectedItems}
                               setSelectedItems={setSelectedItems}
                               style={{
                                 borderRadius: "8px",
                                 border: "1px solid #ccc",
                                 padding: "5px",
                                 width: "60%",
                                 backgroundColor: "white",
                                 textAlign: "center",
                                 fontSize: "1rem",
                               }}
                             />
      </div>
      
      <div>
        <h3 style={{ textAlign: 'left' }}>Selected Resources</h3>
        <hr />
        <div style={{ maxHeight: '200px', overflowY: 'auto',marginTop:"10px" }}>
        <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><strong>Name</strong></TableCell>
              <TableCell><strong>Type</strong></TableCell>
              <TableCell><strong>Details</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((resource,index) => (
              <TableRow key={index}>
                <TableCell>{resource['Resource Name']}</TableCell>
                <TableCell>{resource['Type']}</TableCell>
                <TableCell>{resource['Subscription Names']}</TableCell>
                {/* <TableCell>
                  <Button size="small" color="error">
                    Remove
                  </Button>
                </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
        </div>
      </div>
      
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
        <Button   sx={{ color: "#00243D" }} onClick={handleTagClick} >Tag</Button>
        <Button sx={{ color: "#00243D" }}>Cancel</Button>
      </div>
    </div>
  )
}

export default ResourceTag