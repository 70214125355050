import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ResourceTag from "./ResourceTag";
import { set } from "react-hook-form";
import CommonModal from "../CommonModal";


// CustomToolbar defined outside to prevent re-creation
const CustomToolbar = ({
  columns,
  selectedColumn,
  setSelectedColumn,
  operator,
  setOperator,
  onFilterApply,
  rowSelectionData,
  handleTagButtonClick
}) => {
  const filterInputRef = useRef();

  return (
    <GridToolbarContainer>
      <GridToolbarExport
        printOptions={{ disableToolbarButton: true }}
        csvOptions={{ fileName: 'exported_data' }}
      />
      <GridToolbarFilterButton />
      <Box ml="auto">
      {/* { rowSelectionData.length>0 && <Button variant="contained" sx={{backgroundColor:"#0073AB",
      margin: "0 10px",
         '&:hover': {
          transform: 'translateY(-1px)',
          boxShadow: 3,
          backgroundColor:"#00243D"
        },

      }} size="small" 
      onClick={handleTagButtonClick} >
                            Tag Selected Resources
                        </Button>} */}
              <GridToolbarQuickFilter />
      </Box>
    </GridToolbarContainer>
  );
};

function OnPremise() {
  const [data, setData] = useState([]);
  const navigation = useNavigate();
  const [columns, setColumns] = useState([]);
  const [selectedColumn, setSelectedColumn] = useState("");
  const [operator, setOperator] = useState("contains");
  const [filterValue, setFilterValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
  const [rowSelectionData, setRowSelectionData] = React.useState([]);
    const [openserverenvmodal, setopenserverenvmodal] = useState(false);
  

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 50,
    page: 0,
  });

  const handleTagButtonClick = () => {
    setopenserverenvmodal(true);











  }
  const handleFilter = useCallback(() => {
    let filtered = data;

    if (operator === "contains") {
      filtered = data?.filter((row) =>
        String(row[selectedColumn])
          .toLowerCase()
          .includes(filterValue.toLowerCase())
      );
    } else if (operator === "equals") {
      filtered = data?.filter((row) => String(row[selectedColumn]) === filterValue);
    }
    setFilteredData(filtered);
  }, [data, operator, selectedColumn, filterValue]);

  const handleFilterApply = useCallback(
    (newFilterValue) => {
      setFilterValue(newFilterValue);
      let filtered = data;

      if (operator === "contains") {
        filtered = data?.filter((row) =>
          String(row[selectedColumn])
            .toLowerCase()
            .includes(newFilterValue.toLowerCase())
        );
      } else if (operator === "equals") {
        filtered = data?.filter((row) => String(row[selectedColumn]) === newFilterValue);
      }

      setFilteredData(filtered);
    },
    [data, operator, selectedColumn]
  );

  const handleServerClick = (value) => {
    const serverName = value;
    const existingTabId = localStorage.getItem('serverTabId');
    if (!existingTabId) {
      const newTab = window.open(`/server-specifications?servername=${encodeURIComponent(serverName)}`, '_blank');
      if (newTab) {
        const newTabId = `serverTab-${Date.now()}`;
        newTab.name = newTabId;
        localStorage.setItem('serverTabId', newTabId);
      }
    } else {
      const existingTabWindow = window.open('', existingTabId);
      if (existingTabWindow && !existingTabWindow.closed) {
        existingTabWindow.postMessage({ serverName }, '*');
      } else {
        const newTab = window.open(`/server-specifications?servername=${encodeURIComponent(serverName)}`, '_blank');
        if (newTab) {
          const newTabId = `serverTab-${Date.now()}`;
          newTab.name = newTabId;
          localStorage.setItem('serverTabId', newTabId);
        }
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URI}/dashboardData?function=onpremise_assets`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },      });
      const data = await response.json();
      setData(data?.data);
      setFilteredData(data?.data);
      const columns = Object.keys(data?.data[0] || {});
      const desiredOrder = ["Computer Name", "Code Name", "Host Name","Platform","Primary IP","Resource ID","UUID"]; // Replace with your desired column names
      // Reorder the columns array based on the desired order
      const orderedColumns = desiredOrder.filter(column => columns.includes(column));
      setColumns(orderedColumns);
      setSelectedColumn(orderedColumns[0] || "");
  
    };
    fetchData();
  }, []);

  function getRowId(row) {
    return row['Resource ID'];
  }

  const handleSelectionChange = (newSelection) => {
    const selectedRowsData = newSelection.map((id) => data?.find((row) => getRowId(row) === id));
    setRowSelectionData( selectedRowsData);
    console.log("Selected Rows Data:", rowSelectionData);
  };

  const handleLinkClick = (row) => {
    console.log("Row Data:", row);
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "90vh", overflow: "auto", flexDirection: "column" }}>
      <CommonModal
        isOpen={openserverenvmodal}
        onClose={() => setopenserverenvmodal(false)}
      >
        <ResourceTag data={rowSelectionData} />
      </CommonModal>
      <Box sx={{ display: "flex", height: "90%", width: "90%", minHeight: 300, flexDirection: "column" }}>
        <Typography variant="h5" component="div" sx={{ mb: 2 }}>
          Os Query Servers
        </Typography>
        <DataGrid
          sx={{ fontSize: "0.8em" }}
          loading={data?.length === 0}
          getRowId={getRowId}
          handleSelectionChange={handleSelectionChange}
          columns={columns
            ?.filter((column) => column !== "Resource ID")
            .map((column, index) => ({
              field: column,
              flex: 1,
              // disableColumnMenu: true,
            
                renderCell: (params) => {
                  if (Array.isArray(params.value)) {
                    return params.value.join(", ");
                  }
                if (index === 0) {
                  return (
                    <button
                      style={{ color: "#42b0d5", cursor: "pointer", background: "none", border: "none", padding: 0 }}
                      onClick={(e) => {
                        console.log("Row Data:", params.row['Computer Name']);
                        handleServerClick(params.row['Computer Name']);
                      }}
                    >
                      {params.value}
                    </button>
                  );
                }
                return params.value;
              },
            }))}
          rows={filteredData}
          checkboxSelection
          density="standard"
          slots={{
            toolbar: () => (
              <CustomToolbar
              handleTagButtonClick={handleTagButtonClick}
                columns={columns}
                selectedColumn={selectedColumn}
                setSelectedColumn={setSelectedColumn}
                operator={operator}
                setOperator={setOperator}
                onFilterApply={handleFilterApply}
                rowSelectionData={rowSelectionData}
              />
            ),
          }}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
            handleSelectionChange(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
          autosizeOnMount={true}
          disableRowSelectionOnClick
        />
      </Box>
    </Box>
  );
}

export default OnPremise;