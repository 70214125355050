/**
 * Home component that handles various states and functionalities related to the application.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @returns {JSX.Element} The rendered Home component.
 *
 * @example
 * <Home />
 *
 * @typedef {Object} TableContext
 * @property {Array} table1 - The first table data.
 * @property {Function} setTable1 - Function to set the first table data.
 * @property {string} applicationName - The name of the application.
 * @property {Function} setapplicationName - Function to set the application name.
 * @property {Function} setDeletedRows - Function to set the deleted rows.
 * @property {boolean} showCommit - Flag to show commit.
 * @property {Function} setshowCommit - Function to set the show commit flag.
 * @property {Array} graphData - The graph data.
 * @property {Function} setGraphData - Function to set the graph data.
 * @property {Function} fetchUserDetails - Function to fetch user details.
 * @property {boolean} rated - Flag indicating if rated.
 * @property {boolean} retired - Flag indicating if retired.
 * @property {Function} setRetired - Function to set the retired flag.
 * @property {Function} setRated - Function to set the rated flag.
 * @property {string} applicationId - The application ID.
 * @property {Function} setApplicationId - Function to set the application ID.
 * @property {string} token - The authentication token.
 * @property {boolean} showCertify - Flag to show certify.
 * @property {Function} setShowCertify - Function to set the show certify flag.
 * @property {boolean} disableCertify - Flag to disable certify.
 * @property {Function} setDisableCertify - Function to set the disable certify flag.
 * @property {boolean} showSubscribe - Flag to show subscribe.
 * @property {Function} setShowSubscribe - Function to set the show subscribe flag.
 * @property {boolean} showUnsubscribe - Flag to show unsubscribe.
 * @property {Function} setShowUnsubscribe - Function to set the show unsubscribe flag.
 * @property {boolean} certificationSuccessful - Flag indicating if certification is successful.
 * @property {Function} setCertificationSuccessful - Function to set the certification successful flag.
 * @property {string} certificationResult - The certification result.
 * @property {Function} setCertificationResult - Function to set the certification result.
 * @property {boolean} nextCertify - Flag indicating the next certify.
 * @property {Function} setNextCertify - Function to set the next certify flag.
 * @property {Array} data - The data.
 * @property {Function} setData - Function to set the data.
 * @property {Function} fetchData - Function to fetch data.
 * @property {string} applicationOwner - The application owner.
 * @property {Function} setApplicationOwner - Function to set the application owner.
 * @property {boolean} showTooltip - Flag to show tooltip.
 * @property {Function} setShowTooltip - Function to set the show tooltip flag.
 * @property {boolean} autosearch - Flag indicating if autosearch is enabled.
 * @property {Function} setautosearch - Function to set the autosearch flag.
 * @property {number} tableRightBoundary - The right boundary of the table.
 * @property {Function} fetchOwnership - Function to fetch ownership.
 * @property {boolean} showcreatebutton - Flag to show create button.
 * @property {Function} setshowcreatebutton - Function to set the show create button flag.
 * @property {Array} table5 - The fifth table data.
 * @property {Function} setTable5 - Function to set the fifth table data.
 * @property {Array} relationshipCategory - The relationship category.
 * @property {Object} relationshipCategorySelected - The selected relationship category.
 * @property {Function} setRelationshipCategorySelected - Function to set the selected relationship category.
 * @property {number} tabIndex - The tab index.
 * @property {Function} setTabIndex - Function to set the tab index.
 * @property {Function} fetchWithTimeout - Function to fetch with timeout.
 * @property {Function} fetchAppData - Function to fetch application data.
 * @property {Array} appData - The application data.
 * @property {Function} setAppData - Function to set the application data.
 * @property {boolean} selectedSuggestion - Flag indicating if a suggestion is selected.
 * @property {Function} setSelectedSuggestion - Function to set the selected suggestion flag.
 * @property {boolean} isRetired - Flag indicating if retired.
 * @property {Function} setIsRetired - Function to set the retired flag.
 * @property {Array} cloudData - The cloud data.
 * @property {Function} setCloudData - Function to set the cloud data.
 * @property {Array} subscriptionSuggestions - The subscription suggestions.
 * @property {Function} setSubscriptionSuggestions - Function to set the subscription suggestions.
 * @property {Array} table8 - The eighth table data.
 * @property {Function} setTable8 - Function to set the eighth table data.
 * @property {Array} serverData - The server data.
 * @property {Function} setServerData - Function to set the server data.
 * @property {Function} fetchServerData - Function to fetch server data.
 * @property {string} inputserverName - The input server name.
 * @property {Function} setInputserverName - Function to set the input server name.
 * @property {boolean} applicationManagerDisplay - Flag to display application manager.
 * @property {Function} setApplicationManagerDisplay - Function to set the application manager display flag.
 * @property {boolean} suggestionSelected - Flag indicating if a suggestion is selected.
 * @property {Function} setSuggestionSelected - Function to set the suggestion selected flag.
 * @property {string} appStatus - The application status.
 * @property {Function} setAppStatus - Function to set the application status.
 * @property {Array} retiredApps - The retired applications.
 * @property {Function} setretiredApps - Function to set the retired applications.
 * @property {boolean} showOwner - Flag to show owner.
 * @property {Function} setShowOwner - Function to set the show owner flag.
 */
import React, {
  useState,
  useEffect,
  useContext,
  useReducer,
  useRef,
} from "react";
import "../App.css";
import axios from "axios";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Table from "./Table";
// import { Button } from "@anchor/react-components";
import { TableContext } from "../context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Icon } from "@anchor/react-components";
import { FcApproval } from "react-icons/fc";
import {
  FaCheckCircle,
  FaExclamation,
  FaExclamationCircle,
  FaTimes,
} from "react-icons/fa";
import { BsExclamation, BsExclamationLg } from "react-icons/bs";
import { Tooltip } from "react-tooltip";
import Select from "react-select";
import Table5 from "./Table5";
import CloudTableFormat from "./CloudTableFormat";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { IconContext } from "react-icons";
import Tree from "./Tree";
import Table8 from "./Table8";
import Table9 from "./Table9";
import Table10 from "./Table10";
import CommonModal from "./CommonModal";
import ServerEnvironmentEditor from "./ServerEnvironmentEditor";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { set } from "react-hook-form";

export default function Home(props) {
  // const [table1, setTable1] = useState([]);
  const {
    table1,
    setTable1,
    applicationName,
    setapplicationName,
    setDeletedRows,
    showCommit,
    setshowCommit,
  } = useContext(TableContext);

  const [suggestions, setSuggestions] = useState("");
  const [openserverenvmodal, setopenserverenvmodal] = useState(false);
  const [servers, setServers] = useState({});
  const [applicationenv, setApplicationEnv] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
 

  const {
    graphData,
    setGraphData,
    fetchUserDetails,
    rated,
    retired,
    setRetired,
    setRated,
    applicationId,
    setApplicationId,
    token,
    showCertify,
    setShowCertify,
    showSubscribe,
    setShowSubscribe,
    showUnsubscribe,
    setShowUnsubscribe,
    disableCertify,
    setDisableCertify,
    certificationSuccessful,
    setCertificationSuccessful,
    certificationResult,
    setCertificationResult,
    nextCertify,
    setNextCertify,
    data,
    setData,
    fetchData,
    applicationOwner,
    setApplicationOwner,
    showTooltip,
    setShowTooltip,
    autosearch,
    setautosearch,
    tableRightBoundary,
    fetchOwnership,
    showcreatebutton,
    setshowcreatebutton,
    table5,
    setTable5,
    relationshipCategory,
    relationshipCategorySelected,
    setRelationshipCategorySelected,
    tabIndex,
    setTabIndex,
    fetchWithTimeout,
    fetchAppData,
    appData,
    setAppData,
    selectedSuggestion,
    setSelectedSuggestion,
    isRetired,
    setIsRetired,
    cloudData,
    setCloudData,
    subscriptionSuggestions,
    setSubscriptionSuggestions,
    table8,
    setTable8,
    serverData,
    setServerData,
    fetchServerData,
    inputserverName,
    setInputserverName,
    applicationManagerDisplay,
    setApplicationManagerDisplay,
    suggestionSelected,
    setSuggestionSelected,
    appStatus,
    setAppStatus,
    retiredApps,
    setretiredApps,
    showOwner,
    setShowOwner,
    repoData,
    setRepoData,
    repoSuggestions,
    setRepoSuggestions,
    repoCiOwner,
    setRepoCiOwner,
    showSaaS,
    setShowSaaS,
    disableSaaS,
    setDisableSaaS,
    showSaaSTooltip,
    setShowSaaSTooltip,
  } = useContext(TableContext);
  const [showTable, setshowTable] = useState(false);
  const inputRef = useRef(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [width, setWidth] = useState("");
  const [suggestionList, setSuggestionList] = useState([]);
  const [displaySuggestion, setDisplaySuggestion] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [uniqueValues, setuniqueValues] = useState([]);
  const [serverName, setserverName] = useState("");
  const [subscriptionName, setSubscriptionName] = useState("");
  const [repositoryName, setRepositoryName] = useState("");
  const [repoInputValue, setRepoInputValue] = useState("");
  const [selectedRepos, setSelectedRepos] = useState([]);
  const [repoSuggestionSelected, setRepoSuggestionSelected] = useState(false);
  const [currentRepoName, setCurrentRepoName] = useState("");
  const [tempSelectedRepos, setTempSelectedRepos] = useState([]);
  const [repoSearched, setRepoSearched] = useState(false);
  const [applicationinputValue, setapplicationInputValue] = useState("");
  const [searchbarName, setSearchbarName] = useState("");
  const [arrowStatus, setArrowStatus] = useState(false);
  const [apptorepoflag, setapptorepoflag] = useState(false);
  const [repoSuggestionsAvailable, setRepoSuggestionsAvailable] = useState(false);
  let debounceTimer;

  const relationshipType = [
    { value: "", label: "Select an option", isDisabled: true },
    {
      value: "upstream",
      label: `upstream - Data flows from input app to ${applicationName}`,
    },
    {
      value: "downstream",
      label: `downstream - Data flows from ${applicationName} to input app`,
    },
  ];
  const [relationshipTypeSelected, setRelationshipType] = useState(
    relationshipCategory[0]
  );

  const [selectedCreateSuggestion, setSelectedCreateSuggestion] =
    useState(false);

  const [serverOptions, setServerOptions] = useState([]);
  const [selectedServerOption, setSelectedServerOption] = useState([]);
  const [applicationNameSelected, setapplicationNameSelected] = useState([]);

  const handleMultiSelectServerChange = (selected) => {
    setSelectedServerOption(selected);
    console.log(`Option selected:`, selected);
  };

  const handleSubscriptionNameChange = (event) => {
    setSubscriptionName(event.target.value);
  };
  
  const handleRepoInputChange = (event) => {
    const value = event.target.value;
    setRepoInputValue(value);
    setRepoSuggestionSelected(false);
    const lastCommaIndex = value.lastIndexOf(',');
    const currentRepo = value.slice(lastCommaIndex + 1).trim();
    setCurrentRepoName(currentRepo);
  };

  // Toggle selection of repositories
  const handleCheckboxChange = (repoName) => {
      setTempSelectedRepos((prevSelected) => {
          if (prevSelected.includes(repoName)) {
              return prevSelected.filter((name) => name !== repoName); // Unselect
          } else {
              return [...prevSelected, repoName]; // Select
          }
      });
  };

  // Create a function to clear selected repos and update the input value
  const handleDone = () => {
      if (tempSelectedRepos.length > 0) {
          // Update the repoInputValue with the selected repositories
          setRepoInputValue((prev) => {
            const cleanedValue = prev
              .split(",")
              .map((name) => name.trim())
              .filter((name) => name !== currentRepoName && name !== "")
              .join(", ");
            const updatedValue = cleanedValue + (cleanedValue ? ", " : "") + tempSelectedRepos.join(", ") + ", ";
            return updatedValue;
          });
          setSelectedRepos(tempSelectedRepos); // Finalize the selected repositories
          setRepoSuggestionSelected(true);
      }
      const suggestionDiv = document.getElementById("suggestion-box3");
      suggestionDiv.style.display = "none";
      setTempSelectedRepos([]);
  };

  const handleRepoSuggestionCancel = () => {
    // Clear the temporary selected repositories
    setTempSelectedRepos([]);

    // Update the repoInputValue to exclude the currentRepoName
    setRepoInputValue((prev) => {
      const cleanedValue = prev
        .split(",")
        .map((name) => name.trim())
        .filter((name) => name !== currentRepoName && name !== "")
        .join(", ");
      const updatedValue = cleanedValue + (cleanedValue ? ", " : "");
      return updatedValue;
    });

    // Hide the suggestion box
    const suggestionDiv = document.getElementById("suggestion-box3");
    suggestionDiv.style.display = "none";
  };

  // Effect for fetching repo suggestions
  useEffect(() => {
      if (!repoSuggestionSelected) {
          const getRepositorySuggestion2 = setTimeout(async () => {
              const searchBox = document.getElementById("lname");
              const suggestionBox = document.getElementById("scrollable-suggestions");
              const searchTerm = currentRepoName;
              setRepoSuggestionsAvailable(false);
              // Make an AJAX request to fetch repository names
              if (searchTerm.length >= 3) {
                  suggestionBox.innerHTML = "Loading...";
                  suggestionBox.style.display = "block";
                  searchBox.style.borderBottomLeftRadius = "0%";
                  searchBox.style.borderBottomRightRadius = "0%"; // Show the suggestion box

                  const url = `${process.env.REACT_APP_BACKEND_URI}/getallreposlist?repoName=${encodeURIComponent(searchTerm)}`;
                  fetchWithTimeout(url, {
                      headers: {
                          "Content-Type": "application/json",
                          Authorization: `Bearer ${localStorage.getItem("token")}`,
                      },
                  })
                  .then((response) => response.json())
                  .then((data) => {
                      if (data != "") {
                          setRepoSuggestionsAvailable(true);
                          suggestionBox.innerHTML = "";
                          const maxSuggestions = 20;

                          // Filter out selected repos
                          const filteredData = data.filter((repo) => !selectedRepos.includes(repo));

                          for (let i = 0; i < filteredData.length && i < maxSuggestions; i++) {
                            const repoName = filteredData[i].replace("Maersk-Global/", "");
                            const suggestionItem = document.createElement("div");
                            suggestionItem.classList.add("suggestion-item2");

                            // Add repository name first
                            suggestionItem.appendChild(document.createTextNode(repoName));

                            // Add checkbox after the name (right-aligned)
                            const checkbox = document.createElement("input");
                            checkbox.type = "checkbox";
                            checkbox.checked = tempSelectedRepos.includes(repoName);
                            checkbox.addEventListener("change", () => handleCheckboxChange(repoName));
                           
                            suggestionItem.appendChild(checkbox);
                            suggestionBox.appendChild(suggestionItem);
                        }


                          if (filteredData.length > 0) {
                              suggestionBox.style.display = "block";
                          } else {
                              suggestionBox.style.display = "none";
                          }
                      } else {
                          setRepoSuggestionsAvailable(true);
                          suggestionBox.innerHTML = "No results found";
                          suggestionBox.style.display = "block";
                      }
                  })
                  .catch((error) => console.error("Error fetching data:", error));
              } else {
                  suggestionBox.style.display = "none";
              }
          }, 500);
          return () => clearTimeout(getRepositorySuggestion2);
      }
  }, [repoInputValue, selectedRepos]);

  /**
   * Function to show and hide the create form
   */
  const showCreate = () => {
    setapplicationInputValue("");
    var x = document.getElementById("createDiv");
    x.style.display = "flex";
  };
  const hideCreate = (e) => {
    // e.preventDefault();
    var x = document.getElementById("createDiv");
    x.style.display = "none";
    setserverName("");
    setapplicationInputValue("");
    setRepoInputValue("");
  };

  useEffect(() => {
    if (Object.keys(servers).length > 0) {
      console.log("Inside env modal if");
      setopenserverenvmodal(true);
    }
  }, [servers]);

  /**
   * Function to handle the application name change
   */
  /**
   * Handles the change event for the application name input field.
   *
   * @param {Object} event - The event object from the input field.
   * @param {Object} event.target - The target element of the event.
   * @param {string} event.target.value - The current value of the input field.
   *
   * @returns {void}
   */
  const handleapplicationNameChange = (event) => {
    setSuggestionSelected(false);
    if (relationshipCategorySelected.label == "Server to App") {
      setSelectedSuggestion(true);
      setInputserverName(event.target.value);
    } else if (relationshipCategorySelected.label == "App to Repository") {
      setapplicationName(event.target.value);
      setSearchbarName(event.target.value);
      setArrowStatus(false);
    } else {
      setapplicationName(event.target.value);
    }
  };
  useEffect(() => {
    if (!suggestionSelected) {
      const getApplicationSuggestion = setTimeout(async () => {
        const searchBox = document.getElementById("application-name");
        const suggestionBox = document.getElementById("suggestion-box");
        const searchTerm = searchBox.value;
        // Make an AJAX request to fetchWithTimeout application names from the server
        if (searchTerm.length >= 3) {
          suggestionBox.innerHTML = "Loading...";
          suggestionBox.style.display = "block";
          searchBox.style.borderBottomLeftRadius = "0%";
          searchBox.style.borderBottomRightRadius = "0%"; // Show the suggestion box
          suggestionBox.style.padding = "10px";
          const url = /^[Aa]\d{2,}$/.test(searchTerm)
            ? `${process.env.REACT_APP_BACKEND_URI}/getappidlist?applicationID=${encodeURIComponent(
                searchTerm
              )}`
            : `${process.env.REACT_APP_BACKEND_URI}/?applicationName=${encodeURIComponent(
                searchTerm
              )}`;
          fetchWithTimeout(url, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
            .then((response) => response.json())
            .then((data) => {
              if (data != "") {
                // Clear previous suggestions
                suggestionBox.innerHTML = "";
                // Display a maximum of 20 suggestions
                const maxSuggestions = 20;
                for (let i = 0; i < data.length && i < maxSuggestions; i++) {
                  const suggestionItem = document.createElement("div");
                  suggestionItem.classList.add("suggestion-item");
                  suggestionItem.textContent = data[i];
                  suggestionBox.style.padding = "0px";
                  // Add event listener to each suggestion
                  if(relationshipCategorySelected.value == "app_to_repository"){
                    suggestionItem.addEventListener("click", async function () {
                      setSearchbarName(data[i]);
                      setapptorepoflag(true);
                      searchBox.style.borderBottomLeftRadius = "5px";
                      searchBox.style.borderBottomRightRadius = "5px";
                      suggestionBox.style.display = "none"; // Hide the suggestion box on click
      
                      // Fetch additional data based on the selected item
                      if (/^[Aa]\d{2,}$/.test(searchTerm)) {
                        await fetchAdditionalAppData(data[i], props);
                        setSuggestionSelected(true);
                        hideCreate();
                        setshowcreatebutton(false);
                        if(arrowStatus){
                          setSelectedSuggestion(true);
                         
                        }
                      } else {
                        setSelectedSuggestion(true);
                        setSuggestionSelected(true);
                        hideCreate();
                        setshowcreatebutton(false);
                        await setapplicationName(data[i]);
                      }
                    });
                  } else {
                    suggestionItem.addEventListener("click", function () {
                      setapplicationName(data[i]);
                      searchBox.style.borderBottomLeftRadius = "5px";
                      searchBox.style.borderBottomRightRadius = "5px";
                      suggestionBox.style.display = "none"; // Hide the suggestion box on click
                      setSelectedSuggestion(true);
                      setSuggestionSelected(true);
                      hideCreate();
                      setshowcreatebutton(false);
                    });
                  }                  
                  suggestionBox.appendChild(suggestionItem);
                }
                if (data.length > 0 && searchTerm.length >= 3) {
                  suggestionBox.style.display = "block";
                  searchBox.style.borderBottomLeftRadius = "0%";
                  searchBox.style.borderBottomRightRadius = "0%"; // Show the suggestion box
                  setSelectedSuggestion(false);
                } else {
                  suggestionBox.style.display = "none";
                  searchBox.style.borderBottomLeftRadius = "5px";
                  searchBox.style.borderBottomRightRadius = "5px"; // Hide if there are no suggestions
                }
              } else {
                setSelectedSuggestion(true);
                suggestionBox.innerHTML = "No results found";
                suggestionBox.style.display = "block";
                suggestionBox.style.padding = "10px";
                searchBox.style.borderBottomLeftRadius = "0%";
                searchBox.style.borderBottomRightRadius = "0%"; //
              }
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
        } else {
          suggestionBox.style.display = "none";
          searchBox.style.borderBottomLeftRadius = "5px";
          searchBox.style.borderBottomRightRadius = "5px"; // Hide if the input length is less than 3
        }
      }, 500);
      return () => clearTimeout(getApplicationSuggestion);
    }
  }, [applicationName, relationshipCategorySelected]);

  const handleSuggestionAppIdNames = async (selectedAppId, suggestionBox) => {
    // Fetch additional app data
    await fetchAdditionalAppData(selectedAppId, props);
  };

  // Helper function for fetching app-specific data
  const fetchAdditionalAppData = async (selectedItem, props) => {
    const searchBox = document.getElementById("application-name");
    const suggestionBox = document.getElementById("suggestion-box");
    suggestionBox.innerHTML = "Loading Applications...";
    suggestionBox.style.display = "block";
    searchBox.style.borderBottomLeftRadius = "0%";
    searchBox.style.borderBottomRightRadius = "0%";
    suggestionBox.style.padding = "10px";
    try {
      const response = await fetchWithTimeout(
        `${process.env.REACT_APP_BACKEND_URI}/getappidappsforcertify?applicationName=${encodeURIComponent(
          selectedItem
        )}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = await response.json();
      suggestionBox.innerHTML = ""; // Clear previous suggestions

      if (data.length > 0) {
        // Show suggestions and adjust the search box style
        suggestionBox.style.display = "block";
        searchBox.style.borderBottomLeftRadius = "0%";
        searchBox.style.borderBottomRightRadius = "0%";
        suggestionBox.style.padding = "0px";

        for (let i = 0; i < data.length; i++) {
          const innerSuggestionItem = document.createElement("div");
          innerSuggestionItem.classList.add("suggestion-item");
          innerSuggestionItem.textContent = data[i];

          // Add event listener to each suggestion
          innerSuggestionItem.addEventListener("click", async function () {
            setSearchbarName(`${selectedItem} - ${data[i]}`);
            await setapplicationName(data[i]);
            setSuggestionSelected(true);
            setSelectedSuggestion(true);
            hideCreate();
            setshowcreatebutton(false);
            setArrowStatus(true);
            searchBox.style.borderBottomLeftRadius = "5px";
            searchBox.style.borderBottomRightRadius = "5px";
            suggestionBox.style.display = "none"; // Hide the suggestion box on click
          });
          suggestionBox.appendChild(innerSuggestionItem);
        }
      } else {
        suggestionBox.innerHTML = "";
        suggestionBox.style.display = "none";
        searchBox.style.borderBottomLeftRadius = "5px";
        searchBox.style.borderBottomRightRadius = "5px"; // Hide if the input length is less than 3
      }
    } catch (error) {
      console.error("Error fetching additional app data:", error);
    }
  };
  /**
   * Function to handle the repository name change
   */
  /**
   * Handles the change event for the repository name input field.
   *
   * @param {Object} event - The event object from the input field.
   * @param {Object} event.target - The target element of the event.
   * @param {string} event.target.value - The current value of the input field.
   *
   * @returns {void}
   */
  const handleSuggestedRepoNameChange = (event) => {
    setSuggestionSelected(false);
    setRepositoryName(event.target.value);
  };
  useEffect(() => {
    if (!suggestionSelected) {
      const getRepositorySuggestion = setTimeout(async () => {
        const searchBox = document.getElementById("repository-name");
        const suggestionBox = document.getElementById("suggestion-box2");
        const searchTerm = searchBox.value;
        // Make an AJAX request to fetchWithTimeout repository names from the server
        if (searchTerm.length >= 3) {
          suggestionBox.innerHTML = "Loading...";
          suggestionBox.style.display = "block";
          searchBox.style.borderBottomLeftRadius = "0%";
          searchBox.style.borderBottomRightRadius = "0%"; // Show the suggestion box
          suggestionBox.style.padding = "10px";
          const url = (tabIndex === 0)
            ? `${process.env.REACT_APP_BACKEND_URI}/getrelatedreposlist?applicationName=${encodeURIComponent(
                applicationName
              )}&repoName=${encodeURIComponent(
                searchTerm
              )}`
            : `${process.env.REACT_APP_BACKEND_URI}/getallreposlist?repoName=${encodeURIComponent(
                searchTerm
              )}`;
          fetchWithTimeout(url, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
            .then((response) => response.json())
            .then((data) => {
              if (data != "") {
                // Clear previous suggestions
                suggestionBox.innerHTML = "";
                // Display a maximum of 20 suggestions
                const maxSuggestions = 20;
                for (let i = 0; i < data.length && i < maxSuggestions; i++) {
                  data[i]=data[i].replace("Maersk-Global/", "");
                  const suggestionItem = document.createElement("div");
                  suggestionItem.classList.add("suggestion-item");
                  suggestionItem.textContent = data[i];
                  suggestionBox.style.padding = "0px";
                  suggestionItem.addEventListener("click", function () {
                    setRepositoryName(data[i]);
                    searchBox.style.borderBottomLeftRadius = "5px";
                    searchBox.style.borderBottomRightRadius = "5px";
                    suggestionBox.style.display = "none"; // Hide the suggestion box on click
                    setSelectedSuggestion(true);
                    setSuggestionSelected(true);
                  });
                  suggestionBox.appendChild(suggestionItem);
                }
                if (data.length > 0 && searchTerm.length >= 3) {
                  suggestionBox.style.display = "block";
                  searchBox.style.borderBottomLeftRadius = "0%";
                  searchBox.style.borderBottomRightRadius = "0%"; // Show the suggestion box
                  setSelectedSuggestion(false);
                } else {
                  suggestionBox.style.display = "none";
                  searchBox.style.borderBottomLeftRadius = "5px";
                  searchBox.style.borderBottomRightRadius = "5px"; // Hide if there are no suggestions
                }
              } else {
                setSelectedSuggestion(true);
                suggestionBox.innerHTML = "No results found";
                suggestionBox.style.display = "block";
                suggestionBox.style.padding = "10px";
                searchBox.style.borderBottomLeftRadius = "0%";
                searchBox.style.borderBottomRightRadius = "0%"; //
              }
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
        } else {
          suggestionBox.style.display = "none";
          searchBox.style.borderBottomLeftRadius = "5px";
          searchBox.style.borderBottomRightRadius = "5px"; // Hide if the input length is less than 3
        }
      }, 500);
      return () => clearTimeout(getRepositorySuggestion);
    }
  }, [repositoryName]);

  const handleClearClick = () => {
    setRepositoryName('');
    if(repoSearched){
      fetch(`${process.env.REACT_APP_BACKEND_URI}/getsuggestedrepos`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          applicationName: applicationName,
          user_name: graphData?.displayName,
        }),
      })
        .then((res) => res.json(), props.setProgress(10))
        .then((response) => {
          const data = response;  
          if (data?.suggested_data.length === 0) {
            setRepoSuggestions([]);
            props.setProgress(100);
          } else {
              setRepoSuggestions([data]);
              props.setProgress(100);
              console.log("Test RepoSuggestions data: ", repoSuggestions);
          }
          setRepoSearched(false);
        });
    }
  };

  /**
   * Function to handle the server name change
   */
  const handleserverNameChange = (event) => {
    setserverName(event.target.value);
  };
  // Handler function to update input value and trigger debounced API call
  const handleinputApplicationNameChange = (event) => {
    const value = event.target.value;
    setapplicationInputValue(value);
    setSuggestionSelected(false); // Log the API call after the debounce delay

    // clearTimeout(debounceTimer); // Clear the existing debounce timer
    // debounceTimer = setTimeout(() => {
    //   // Your API call logic here
    //   console.log("API called with input:", value); // Log the API call after the debounce delay
    // }, 5000);
    // debouncedAPICall(value);
  };

  useEffect(() => {
    if (!suggestionSelected) {
      const getApplicationSuggestion = setTimeout(async () => {
        const searchBox = document.getElementById("applicationInputName");
        const suggestionBox = document.getElementById("suggestion-container2");
        setSelectedCreateSuggestion(false);
        console.log(applicationinputValue);
        if (applicationinputValue.length >= 3) {
          const category_select =
            relationshipCategorySelected.value == "app_to_app" ? "create" : "";
          await fetchWithTimeout(
            `${process.env.REACT_APP_BACKEND_URI}/getappsforcertify?applicationName=${encodeURIComponent(
              applicationinputValue
            )}&category=${category_select}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
            .then((response) => response.json())
            .then((data) => {
              console.log("data", data);
              // Clear previous suggestions
              suggestionBox.innerHTML = "";
              // Display a maximum of 20 suggestions
              const maxSuggestions = 20;
              for (let i = 0; i < data.length && i < maxSuggestions; i++) {
                const suggestionItem = document.createElement("div");
                suggestionItem.classList.add("suggestion-item");
                suggestionItem.textContent = data[i];
                suggestionItem.addEventListener("click", async function () {
                  setapplicationInputValue(data[i]);
                  setSuggestionSelected(true);
                  // await setcertifyapplicationName(data[i]);
                  searchBox.style.borderBottomLeftRadius = "5px";
                  searchBox.style.borderBottomRightRadius = "5px";
                  suggestionBox.style.display = "none"; // Hide the suggestion box on click
                  // await isciadmin();
                  setSelectedCreateSuggestion(true);
                });
                suggestionBox.appendChild(suggestionItem);
              }
              if (data.length > 0) {
                suggestionBox.style.display = "block";
                searchBox.style.borderBottomLeftRadius = "0%";
                searchBox.style.borderBottomRightRadius = "0%"; // Show the suggestion box
              } else {
                suggestionBox.style.display = "none";
                searchBox.style.borderBottomLeftRadius = "5px";
                searchBox.style.borderBottomRightRadius = "5px"; // Hide if there are no suggestions
              }
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
        }
        if (applicationinputValue.length == 0) {
          console.log("No data");
          suggestionBox.innerHTML = "";
          suggestionBox.style.display = "none";
          searchBox.style.borderBottomLeftRadius = "5px";
          searchBox.style.borderBottomRightRadius = "5px"; // Hide if the input length is less than 3
        }

        console.log("API called with input:", applicationinputValue);
      }, 500);
      return () => clearTimeout(getApplicationSuggestion);
    }
  }, [applicationinputValue]);

  const handleShowCertify = async (toastMessage) => {
    try {
      // console.log("Inside try block");
      const response = await fetchWithTimeout(
        `${process.env.REACT_APP_BACKEND_URI}/showcertify`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            applicationName: applicationName,
            logged_in_user: graphData?.displayName,
            relationship_category: relationshipCategorySelected.value,
          }),
        }
      );
      if (response.ok) {
        const result = await response.json();
        if (/^[Aa]\d{2,}$/.test(applicationName)) {
          setCertificationSuccessful(false);
          const userCheckResult = result.user_check;
          console.log(userCheckResult);
          const pendingRequestResult = result.pending_request;
          console.log(pendingRequestResult);
          const certificationResult1 = result.certification;
          console.log(certificationResult1);

          if (
            certificationResult1 == "Not in Production" ||
            userCheckResult === "invalid" ||
            pendingRequestResult === "invalid" ||
            certificationResult1 === "invalid" ||
            toastMessage === "App is retired" ||
            certificationResult1 == "Invalid relationship category" ||
            certificationResult1 == "retired"
          ) {
            setShowCertify(false);
            setShowSaaS(false);
            if (certificationResult1 == "Invalid relationship category") {
              toast.error("Please select a relationship category", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          } else if(toastMessage === "No Relationship Exist"){
            if (userCheckResult === "Verified") {
              setShowCertify(true);
              setDisableCertify(true);
              setShowTooltip(
                "As Application Manager, you need to first create relationships before certification"
              );
              const saasResponse = await fetchWithTimeout(
                `${process.env.REACT_APP_BACKEND_URI}/get_appid_saas_status?applicationName=${encodeURIComponent(applicationName)}&username=${encodeURIComponent(graphData?.displayName)}`,
                {
                  method: "GET",
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  },
                }
              );
              
              if (saasResponse.ok) {
                const responseData = await saasResponse.json();
                const responseMessage = responseData.message;
              
                if (responseMessage) {
                  setShowSaaS(true);
                  setShowSaaSTooltip(responseMessage);
                  if (responseMessage.includes("already marked as SaaS")) {
                    setDisableSaaS(true);
                  } else if (responseMessage.includes("You can mark the following applications as SaaS")) {
                    setDisableSaaS(false);
                  }
                } else {
                  toast.error("Failed to fetch SaaS status of application", {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                }
              } else {
                toast.error("Error while fetching SaaS status of application", {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              }
            } else{
              setShowCertify(false);
              setShowSaaS(false);
            }
          } else {
            if (userCheckResult === "Verified") {
              setShowTooltip("");
              if (
                pendingRequestResult.includes(
                  "Please clear the pending requests"
                )
              ) {
                setShowCertify(true);
                setDisableCertify(true);
                setShowTooltip(pendingRequestResult);
              } else if (pendingRequestResult.includes("No pending request")) {
                setShowCertify(true);

                if (
                  certificationResult1.tooltip1.includes(
                    "All the applications you own are retired"
                  ) ||
                  certificationResult1.tooltip1.includes(
                    "You have already certified all the applications you own."
                  )
                ) {
                  setDisableCertify(true);
                  setShowTooltip(certificationResult1.tooltip1);
                }
                if (
                  certificationResult1.tooltip1.includes(
                    "You can certify the following applications"
                  )
                ) {
                  setDisableCertify(false);
                  setShowTooltip(certificationResult1.tooltip1);
                }
              }
              if (
                certificationResult1.tooltip1.includes(
                  "All the applications you own are retired"
                )
              ) {
                setShowSaaS(false);
              } else{
                const saasResponse = await fetchWithTimeout(
                  `${process.env.REACT_APP_BACKEND_URI}/get_appid_saas_status?applicationName=${encodeURIComponent(applicationName)}&username=${encodeURIComponent(graphData?.displayName)}`,
                  {
                    method: "GET",
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                  }
                );
                
                if (saasResponse.ok) {
                  const responseData = await saasResponse.json();
                  const responseMessage = responseData.message;
                
                  if (responseMessage) {
                    setShowSaaS(true);
                    setShowSaaSTooltip(responseMessage);
                    if (responseMessage.includes("already marked as SaaS")) {
                      setDisableSaaS(true);
                    } else if (responseMessage.includes("You can mark the following applications as SaaS")) {
                      setDisableSaaS(false);
                    }
                  } else {
                    toast.error("Failed to fetch SaaS status of application", {
                      position: "top-center",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                    });
                  }
                } else {
                  toast.error("Error while fetching SaaS status of application", {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                }
              }
            } else {
              setShowCertify(false);
              setShowSaaS(false);
            }
          }
        } else {
          // Access the properties from the result object
          const userCheckResult = result.user_check;
          console.log(userCheckResult);
          const pendingRequestResult = result.pending_request;
          console.log(pendingRequestResult);
          const certificationResult1 = result.certification;
          console.log(certificationResult1);
          // console.log(certificationResult1.tooltip2)

          // console.log('User Check Result:', userCheckResult);
          // console.log('Pending Request Result:', pendingRequestResult);
          if (
            certificationResult1 == "Not in Production" ||
            userCheckResult === "invalid" ||
            pendingRequestResult === "invalid" ||
            certificationResult1 === "invalid" ||
            toastMessage === "App is retired" ||
            certificationResult1 == "Invalid relationship category" ||
            certificationResult1 == "retired"
          ) {
            setShowCertify(false);
            setShowSaaS(false);
            if (certificationResult1 == "Invalid relationship category") {
              toast.error("Please select a relationship category", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }

            if (certificationResult1 == "Not in Production") {
              setCertificationSuccessful(false);
            }
          } else if(toastMessage === "No Relationship Exist"){
            if (userCheckResult === "Verified") {
              setShowCertify(true);
              setDisableCertify(true);
              setShowTooltip(
                "As Application Manager, you need to first create relationships before certification"
              );
              setShowSaaS(true);
              setShowSaaSTooltip("");
              const saasResponse = await fetchWithTimeout(
                `${process.env.REACT_APP_BACKEND_URI}/get_saas_apps?applicationName=${encodeURIComponent(applicationName)}`,
                {
                  method: "GET",
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  },
                }
              );
              if (saasResponse.ok) {
                saasResponse.json().then(responseText => {
                  if (responseText === "Application is Saas") {
                      setDisableSaaS(true);
                      setCertificationSuccessful(true);
                      setCertificationResult("Marked as SaaS");
                      setShowSaaSTooltip("Application is already marked as SaaS");
                  } else if (responseText === "Application is not Saas") {
                      setDisableSaaS(false);
                      setShowSaaSTooltip("Certify that your app is a SaaS application and does not have any app to infrastructure relationship");
                  } else {
                    toast.error("Error while fetching SaaS status of application", {
                      position: "top-center",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                    });
                  }
                 });
              } else {
                toast.error("Error while fetching SaaS status of application", {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              }
            }
            else{
              setShowCertify(false);
              setShowSaaS(false);
              const saasResponse = await fetchWithTimeout(
                `${process.env.REACT_APP_BACKEND_URI}/get_saas_apps?applicationName=${encodeURIComponent(applicationName)}`,
                {
                  method: "GET",
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  },
                }
              );
              if (saasResponse.ok) {
                saasResponse.json().then(responseText => {
                  if (responseText === "Application is Saas") {
                      setCertificationSuccessful(true);
                      setCertificationResult("Marked as SaaS");
                  } else if (responseText === "Application is not Saas") {
                    setCertificationSuccessful(false);
                  } else {
                    toast.error("Error while fetching SaaS status of application", {
                      position: "top-center",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                    });
                  }
                });
              } else {
                toast.error("Error while fetching SaaS status of application", {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              }
            }
          } else {
            setCertificationSuccessful(certificationResult1 !== "Certify");
            setCertificationResult(certificationResult1.tooltip1);

            if (userCheckResult === "Verified") {
              setShowCertify(true);
              setShowTooltip(
                "As Application Manager, you can review the application relationships and validate by clicking the Certify button at every 180 days"
              );
              setShowSaaS(true);
              setShowSaaSTooltip("");

              if (pendingRequestResult === "Pending request") {
                setDisableCertify(true);
                setShowTooltip(
                  "Please clear the pending requests to certify the application"
                );
              } else if (certificationResult1 !== "Certify") {
                setDisableCertify(true);
                setShowTooltip(certificationResult1?.tooltip2);
                if (
                  certificationResult1?.tooltip2?.includes(
                    "Certification expire"
                  )
                ) {
                  setDisableCertify(false);
                }
              } else {
                setDisableCertify(false);
              }
              const saasResponse = await fetchWithTimeout(
                `${process.env.REACT_APP_BACKEND_URI}/get_saas_apps?applicationName=${encodeURIComponent(applicationName)}`,
                {
                  method: "GET",
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  },
                }
              );
              if (saasResponse.ok) {
                saasResponse.json().then(responseText => {
                  if (responseText === "Application is Saas") {
                      setDisableSaaS(true);
                      setShowSaaSTooltip("Application is already marked as SaaS");
                      setCertificationSuccessful(true);
                      if (certificationResult1 === "Certify") {
                        setCertificationResult("Marked as SaaS");
                      } else {
                        setCertificationResult(prevResult => prevResult + " & Marked as SaaS");
                      }
                  } else if (responseText === "Application is not Saas") {
                      setDisableSaaS(false);
                      setShowSaaSTooltip("Certify that your app is a SaaS application");
                  } else {
                    toast.error("Error while fetching SaaS status of application", {
                      position: "top-center",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                    });
                  }
                });
              } else {
                toast.error("Error while fetching SaaS status of application", {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              }
            } else {
              if (
                certificationResult1?.tooltip2?.includes("Certification expire")
              ) {
                setShowTooltip(certificationResult1.tooltip2);
              }
              setShowCertify(false);
              setShowSaaS(false);
              const saasResponse = await fetchWithTimeout(
                `${process.env.REACT_APP_BACKEND_URI}/get_saas_apps?applicationName=${encodeURIComponent(applicationName)}`,
                {
                  method: "GET",
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  },
                }
              );
              if (saasResponse.ok) {
                saasResponse.json().then(responseText => {
                  if (responseText === "Application is Saas") {
                    setCertificationSuccessful(true);
                    if (certificationResult1 === "Certify") {
                      setCertificationResult("Marked as SaaS");
                    } else {
                      setCertificationResult(prevResult => prevResult + " & Marked as SaaS");
                    }
                  } else if (responseText === "Application is not Saas") {
                      setShowSaaS(false);
                  } else {
                    toast.error("Error while fetching SaaS status of application", {
                      position: "top-center",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                    });
                  }
                });
              } else {
                toast.error("Error while fetching SaaS status of application", {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              }
            }
          }
        }
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      toast.error("Certify Error", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  const handleShowSubscribe = async (toastMessage) => {
    try {
      const response = await fetchWithTimeout(
        `${process.env.REACT_APP_BACKEND_URI}/checkSubscriptionStatus`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            app_name: applicationName,
            email: graphData?.mail,
            relationship_category: relationshipCategorySelected.value,
          }),
        }
      );
      if (response.ok) {
        const subscriptionResult = await response.json();
        console.log(subscriptionResult);
        const certificationResult1 = subscriptionResult.certification;
        const subscriptionStatus = subscriptionResult.subscription_status;

        if (
          certificationResult1 == "Not in Production" ||
          certificationResult1 == "retired"
        ) {
          setShowSubscribe(false);
          setShowUnsubscribe(false);
        } else {
          if (subscriptionStatus == "Subscribed") {
            setShowSubscribe(false);
            setShowUnsubscribe(true);
          } else if (subscriptionStatus == "Not Subscribed") {
            setShowSubscribe(true);
            setShowUnsubscribe(false);
          } else {
            console.error("Failed to fetch subscription status");
          }
        }
      }
    } catch (error) {
      console.error("Error fetching subscription status:", error);
      toast.error("Failed to fetch subscription status", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const fetchCloudData = async () => {
    setSubscriptionSuggestions([]);
    setCloudData([]);
    setShowCertify(false);
    setShowSaaS(false);
    setCertificationResult("");
    setCertificationSuccessful(false);
    setIsRetired(false);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URI}/getclouddata?applicationName=${encodeURIComponent(
          applicationName
        )}&username=${encodeURIComponent(
          graphData?.displayName
        )}&graph_access_token=${token}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      props.setProgress(50);
      const data = await response.json();
      if (data?.app_status === "retired") {
        setshowcreatebutton(false);
        setIsRetired(true);
        toast.warning(
          applicationName +
            " is Retired. Showing info in read-only mode.",
          {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
        if (
          !data?.certified || data?.certified_data.length === 0
        ) {
          toast.warning("No Relationship Exists", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          props.setProgress(100);
        } else {
          data.isciowner = false;
          data.resourceGroups = [];
          setCloudData(data);
          props.setProgress(100);
        }
        return;
      } else {
        setIsRetired(false);
      }
 

      if (data?.app_status === "retired") {
        setshowcreatebutton(false);
        setIsRetired(true);
        toast.warning(
          applicationName +
            " is Retired. Showing info in read-only mode.",
          {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
        if (
          !data?.certified || data?.certified_data.length === 0
        ) {
          toast.warning("No Relationship Exists", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          props.setProgress(100);
        } else {
          data.isciowner = false;
          data.resourceGroups = [];
          setCloudData(data);
          props.setProgress(100);
        }
        return;
      } else {
        setIsRetired(false);
      }
      if (data?.status == "multiple_matches") {
        toast.error(
          "Multiple matches found for the application name. Please select the correct subscription name from the suggestions",
          {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
        // setCloudData(data)
        setCloudData(data);
        console.log(cloudData);
        props.setProgress(100);
        setSubscriptionSuggestions(data?.recommendations);
        if (data?.certified) {
          if (data?.certificationDetails.includes("Certified")) {
            setCertificationSuccessful(true);
            setCertificationResult(data?.certificationDetails);
          }
        }

        if (data?.isciowner === true) {
          setshowcreatebutton(true);
          console.log("isciowner true");
          setShowSaaS(true);
          setShowSaaSTooltip("");
          const saasResponse = await fetchWithTimeout(
            `${process.env.REACT_APP_BACKEND_URI}/get_saas_apps?applicationName=${encodeURIComponent(applicationName)}`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          if (saasResponse.ok) {
            saasResponse.json().then(responseText => {
              if (responseText === "Application is Saas") {
                  setDisableSaaS(true);
                  setShowSaaSTooltip("Application is already marked as SaaS");
                  if (data?.certified) {
                    if (data?.certificationDetails.includes("Certified")) {
                      setCertificationResult(prevResult => prevResult + " & Marked as SaaS"); 
                    } else{
                      setCertificationSuccessful(true);
                      setCertificationResult("Marked as SaaS");
                    }
                  } else{
                    setCertificationSuccessful(true);
                    setCertificationResult("Marked as SaaS");
                  }
              } else if (responseText === "Application is not Saas") {
                  setDisableSaaS(false);
                  setShowSaaSTooltip("Certify that your app is a SaaS application");
              } else {
                toast.error("Error while fetching SaaS status of application", {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              }
            });
          } else {
            toast.error("Error while fetching SaaS status of application", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        } else if (data?.isciowner === false) {
          console.log("isciowner false");
          setshowcreatebutton(false);
          setShowSaaS(false);
          const saasResponse = await fetchWithTimeout(
            `${process.env.REACT_APP_BACKEND_URI}/get_saas_apps?applicationName=${encodeURIComponent(applicationName)}`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          if (saasResponse.ok) {
            saasResponse.json().then(responseText => {
              if (responseText === "Application is Saas") {
                if (data?.certified) {
                  if (data?.certificationDetails.includes("Certified")) {
                    setCertificationResult(prevResult => prevResult + " & Marked as SaaS"); 
                  } else{
                    setCertificationSuccessful(true);
                    setCertificationResult("Marked as SaaS");
                  }
                } else{
                  setCertificationSuccessful(true);
                  setCertificationResult("Marked as SaaS");
                }
              } else if (responseText === "Application is not Saas") {
                  setShowSaaS(false);
              } else {
                toast.error("Error while fetching SaaS status of application", {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              }
            });
          } else {
            toast.error("Error while fetching SaaS status of application", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        }
        // return;
      } else {
        if (
          data?.resourceGroups.length === 0 &&
          data?.certified_data.length === 0
        ) {
          toast.error("No data found for the application", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          props.setProgress(100);
          if (data?.isciowner === true) {
            setshowcreatebutton(true);
            console.log("isciowner true");
            setShowSaaS(true);
            setShowSaaSTooltip("");
            const saasResponse = await fetchWithTimeout(
              `${process.env.REACT_APP_BACKEND_URI}/get_saas_apps?applicationName=${encodeURIComponent(applicationName)}`,
              {
                method: "GET",
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              }
            );
            if (saasResponse.ok) {
              saasResponse.json().then(responseText => {
                if (responseText === "Application is Saas") {
                    setDisableSaaS(true);
                    setShowSaaSTooltip("Application is already marked as SaaS");
                    setCertificationSuccessful(true);
                    setCertificationResult("Marked as SaaS");
                } else if (responseText === "Application is not Saas") {
                    setDisableSaaS(false);
                    setShowSaaSTooltip("Certify that your app is a SaaS application and does not have any app to infrastructure relationship");
                } else {
                  toast.error("Error while fetching SaaS status of application", {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                }
              });
            } else {
              toast.error("Error while fetching SaaS status of application", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          } else if (data?.isciowner === false) {
            console.log("isciowner false");
            setshowcreatebutton(false);
            setShowSaaS(false);
            const saasResponse = await fetchWithTimeout(
              `${process.env.REACT_APP_BACKEND_URI}/get_saas_apps?applicationName=${encodeURIComponent(applicationName)}`,
              {
                method: "GET",
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              }
            );
            if (saasResponse.ok) {
              saasResponse.json().then(responseText => {
                if (responseText === "Application is Saas") {
                    setCertificationSuccessful(true);
                    setCertificationResult("Marked as SaaS");
                } else if (responseText === "Application is not Saas") {
                    setShowSaaS(false);
                } else {
                  toast.error("Error while fetching SaaS status of application", {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                }
              });
            } else {
              toast.error("Error while fetching SaaS status of application", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          }
          return;
        }
        if (data?.certificationDetails.includes("Certified")) {
          setCertificationSuccessful(true);
          setCertificationResult(data?.certificationDetails);
        }
        setCloudData(data);
        props.setProgress(100);
        if (data?.isciowner === true) {
          setshowcreatebutton(true);
          console.log("isciowner true");
          setShowSaaS(true);
          setShowSaaSTooltip("");
          const saasResponse = await fetchWithTimeout(
            `${process.env.REACT_APP_BACKEND_URI}/get_saas_apps?applicationName=${encodeURIComponent(applicationName)}`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          if (saasResponse.ok) {
            saasResponse.json().then(responseText => {
              if (responseText === "Application is Saas") {
                  setDisableSaaS(true);
                  setShowSaaSTooltip("Application is already marked as SaaS");
                  setCertificationResult(prevResult => prevResult + " & Marked as SaaS");    
              } else if (responseText === "Application is not Saas") {
                  setDisableSaaS(false);
                  setShowSaaSTooltip("Certify that your app is a SaaS application");
              } else {
                toast.error("Error while fetching SaaS status of application", {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              }
            });
          } else {
            toast.error("Error while fetching SaaS status of application", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        } else if (data?.isciowner === false) {
          console.log("isciowner false");
          setshowcreatebutton(false);
          setShowSaaS(false);
          const saasResponse = await fetchWithTimeout(
            `${process.env.REACT_APP_BACKEND_URI}/get_saas_apps?applicationName=${encodeURIComponent(applicationName)}`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          if (saasResponse.ok) {
            saasResponse.json().then(responseText => {
              if (responseText === "Application is Saas") {
                  setCertificationResult(prevResult => prevResult + " & Marked as SaaS");    
              } else if (responseText === "Application is not Saas") {
                  setShowSaaS(false);
              } else {
                toast.error("Error while fetching SaaS status of application", {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              }
            });
          } else {
            toast.error("Error while fetching SaaS status of application", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        }
        // setData(await response.json());
        // setLoading(false);
      }
    } catch (error) {
      // setLoading(false);
      if (data.isciowner === true) {
        setshowcreatebutton(true);
      } else if (data.isciowner === false) {
        setshowcreatebutton(false);
      }
      toast.error("Failed to fetch data", {
        position: "top-center", // Add your own message: "Failed to fetch data"
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      props.setProgress(100);
    }
  };

  const fetchRepoData = async () => {
    setRepoData([]);
    setRepoSuggestions([]);
    setShowCertify(false);
    setShowSaaS(false);
    setCertificationResult("");
    setCertificationSuccessful(false);
    setIsRetired(false);
    setRepoCiOwner(false);
    try {
      const [relatedReposResponse, suggestedReposResponse] = await Promise.all([
        fetch(`${process.env.REACT_APP_BACKEND_URI}/getrelatedrepos`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            applicationName: applicationName,
            user_name: graphData?.displayName,
          }),
        }),
        fetch(`${process.env.REACT_APP_BACKEND_URI}/getsuggestedrepos`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            applicationName: applicationName,
            user_name: graphData?.displayName,
          }),
        }),
      ]);
 
      const relatedReposData = await relatedReposResponse.json();
      const suggestedReposData = await suggestedReposResponse.json();
 
      console.log("Related Repos Data", relatedReposData);
      console.log("Suggested Repos Data", suggestedReposData);
      if (relatedReposData?.app_status === "retired") {
        setshowcreatebutton(false);
        setIsRetired(true);
        toast.warning(
          applicationName +
            " is Retired. Showing info in read-only mode.",
          {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
        if (relatedReposData?.certified_data.length === 0) {
          toast.warning(`No Relationship Exists`, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setRepoData([]);
          setRepoSuggestions([]);
        } else {
          relatedReposData.isciowner = false;
          setRepoData([relatedReposData]);
          setRepoSuggestions([]);
        }
        props.setProgress(100);
        return;
      } else {
        setIsRetired(false);
      }
 
 
      if (relatedReposData?.app_status === "retired") {
        setshowcreatebutton(false);
        setIsRetired(true);
        toast.warning(
          applicationName +
            " is Retired. Showing info in read-only mode.",
          {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
        if (relatedReposData?.certified_data.length === 0) {
          toast.warning(`No Relationship Exists`, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setRepoData([]);
          setRepoSuggestions([]);
        } else {
          relatedReposData.isciowner = false;
          setRepoData([relatedReposData]);
          setRepoSuggestions([]);
        }
        props.setProgress(100);
        return;
      } else {
        setIsRetired(false);
      }
 
      setRepoCiOwner(relatedReposData?.isciowner);
      if (relatedReposData?.isciowner === true) {
        setshowcreatebutton(true);
      } else {
        setshowcreatebutton(false);
      }
 
      if (relatedReposData?.certified_data.length === 0) {
        setTabIndex(1);
        toast.warning(`No App to Repository Relationship Exists for ${applicationName}. Hence, showing suggestions.`, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setRepoData([]);
      } else {
        setRepoData([relatedReposData]);
        // toast.success(`Successfully fetched Related Repositories`, {
        //   position: "top-center",
        //   autoClose: 3000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "light",
        // });
      }
 
      if (suggestedReposData?.suggested_data.length === 0) {
        toast.warning("No Suggested Repositories", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setRepoSuggestions([]);
      } else {
        setRepoSuggestions([suggestedReposData]);
        // toast.success(`Successfully fetched Suggested Repositories`, {
        //   position: "top-center",
        //   autoClose: 3000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "light",
        // });
      }
 
      props.setProgress(100);
    } catch (error) {
      console.log(error);
      setTabIndex(1);
      toast.error(`Failed to fetch Repositories Data`, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      props.setProgress(50);
      try {
        const response = await fetchWithTimeout(
          `${process.env.REACT_APP_BACKEND_URI}/relevantSuggestion`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify({
              applicationName: applicationName,
            }),
          }
        );
        if (response.ok) {
          const result = await response.json();
          const relevant_application = result.relevant_application_names;
          setSuggestionList(relevant_application);
          setDisplaySuggestion(true);
        } else {
          console.error("Failed to fetch data");
        }
      } catch (error) {
        console.error("An error occurred:", error);
        toast.error("Failed to fetch Suggestions Badge", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
    props.setProgress(100);
    // await handleShowSubscribe("");
  };
 
 

  /**
   * Function to handle the Repo Name Search Submit
   */
  const handleRepoSearchSubmit = async (e) => {
    e.preventDefault();
    if (selectedSuggestion) {
      try {
        await fetch(`${process.env.REACT_APP_BACKEND_URI}/getrepodata`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            applicationName: applicationName,
            repositoryName: repositoryName,
            user_name: graphData?.displayName,
            tabIndex: tabIndex,
          }),
        })
          .then((res) => res.json(), props.setProgress(10))
          .then((response) => {
            const data = response;
            console.log("Data", data);
            if (data?.suggested_data.length === 0) {
              toast.warning("Failed to fetch repository data", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              setRepoSuggestions([]);
            } else {
              setRepoSuggestions([data]);
              console.log("Test RepoSuggestions data: ", repoSuggestions);
            }
            setRepoSearched(true);
          });
        props.setProgress(100);
      } catch (error) {
        console.log(error);
        toast.error("Failed to fetch repository data", {
          position: "top-center", // Add your own message: "Failed to fetch data"
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } else {
      toast.error("Please select a Repository from the drop down", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  /**
   * Function to handle the search submit
   */
  const handleSearchSubmit = async (e) => {
    setIsButtonDisabled(true);
    e.preventDefault();
    setCloudData([]);
    setRepositoryName("");
    setShowSubscribe(false);
    setShowUnsubscribe(false);
    hideCreate()
    setShowCertify(false);
    setShowSaaS(false);
    setCertificationResult("");
    setCertificationSuccessful(false);
    // setApplicationManagerDisplay(false)

    if (selectedSuggestion) {
      props.setProgress(10);
      setDisplaySuggestion(false);
      const suggestionBox = document.getElementById("suggestion-box");
      suggestionBox.style.display = "none";
      const searchBox = document.getElementById("application-name");
      searchBox.style.borderBottomLeftRadius = "5px";
      searchBox.style.borderBottomRightRadius = "5px";
      let toastMessage = "";
      if (relationshipCategorySelected.value == "") {
        toast.error("Please select a relationship category", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        props.setProgress(100);
      } else {
        if (
          applicationName.length === 0 &&
          relationshipCategorySelected.value != "server_to_app"
        ) {
          toast.error("Application Name Cannot Be Empty", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          props.setProgress(100);
        } else {
          setApplicationEnv();
         
          if (relationshipCategorySelected.value == "app_to_server") {
            setTable1([]);
            setData([]);
            if (/^[Aa]\d{2,}$/.test(applicationName)) {
              console.log(applicationName + "Alphanumeric");
              setapplicationNameSelected([]);
              setserverName("");
              setAppStatus();
              setretiredApps();
              try {
                // const application =applicationName.split(' ').join('+');
                await fetch(
                  `${process.env.REACT_APP_BACKEND_URI}/getappiddata?applicationID=${encodeURIComponent(
                    applicationName
                  )}&graph_access_token=${token}`,
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                  }
                )
                  .then((res) => res.json(), props.setProgress(10))
                  .then((response) => {
                    setApplicationId("");
                    setApplicationManagerDisplay(false);
                    const data = response.data;
                    const appenv = response.app_env;
                    setApplicationEnv(appenv);
                    const filteredStatus = Object.keys(response.status)
                      .filter((key) => response.status[key] !== "retired")
                      .reduce((acc, key) => {
                        acc[key] = response.status[key];
                        return acc;
                      }, {});
                    const retiredKeys = Object.keys(response.status).filter(
                      (key) => response.status[key] === "retired"
                    );

                    console.log("Retired Keys:", retiredKeys);
                    if (retiredKeys.length > 0) {
                      setretiredApps(retiredKeys);
                      const message =
                        retiredKeys.length === 1
                          ? `${retiredKeys[0]} is retired. Showing info for this in read-only mode.`
                          : `${retiredKeys.join(
                              ", "
                            )} are retired. Showing info for these in read-only mode.`;

                      toast.warning(message, {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                      });
                    }

                    setAppStatus(filteredStatus);
                    console.log("Environment", Object.keys(response.status));
                    const appStatus = response?.status;
                    if(data.some(row => row.includes('Not mapped'))){
                      toastMessage = "No Relationship Exist";
                    }
                    if (data.length === 0 || data.includes("Production")) {
                      if (appStatus === "retired") {
                        setshowcreatebutton(false);
                        setIsRetired(true);
                        toast.warning(
                          applicationName +
                            " is Retired. Showing info in read-only mode.",
                          {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                          }
                        );
                      } else {
                        setIsRetired(false);
                      }
                      toastMessage =
                        data.length === 0
                          ? "No Relationship Exist"
                          : "App is retired";
                      if (data.includes("Production")) {
                        toastMessage = data;
                        setshowcreatebutton(false);
                      }
                      if (toastMessage === "App is retired") {
                        setshowcreatebutton(false);
                      }
                      if (
                        toastMessage === "No Relationship Exist" &&
                        appStatus !== "retired"
                      ) {
                        setshowcreatebutton(true);
                      }
                      toast.warning(toastMessage, {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                      });
                      setshowTable(false);
                      setTable1([]);
                      setApplicationId("");
                    } else {
                      setshowcreatebutton(true);
                      if (appStatus === "retired") {
                        setshowcreatebutton(false);
                        setIsRetired(true);
                        toast.warning(
                          applicationName +
                            " is Retired. Showing info in read-only mode.",
                          {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                          }
                        );
                      } else {
                        setIsRetired(false);
                      }
                      setTable1(data);
                      setshowTable(true);
                      setApplicationId(applicationName);
                    }
                    //console.log(data)
                  });
                props.setProgress(70);
                //   var temp =await [...new Set(table1.map(row => row[2]))];
                //  await setuniqueValues(temp)
                //   console.log(uniqueValues)
                props.setProgress(100);
                // await getID(applicationName);
                await fetchData();
                await showRate();
                await setTabIndex(0);
                await handleShowSubscribe(toastMessage);
                await handleShowCertify(toastMessage);

                // sessionStorage.setItem("table",JSON.stringify(table1))
                // console.log(table1[1].indexOf(applicationName))
              } catch (error) {
                if (error.message === "Timeout") {
                  toast.error("Request Timed Out", {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                } else {
                  console.log(error);
                  setshowcreatebutton(false);
                  toast.error(
                    `Record not found in ${relationshipCategorySelected.label}. App names are case sensitive, please select from below suggestions.`,
                    {
                      position: "top-center",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                    }
                  );
                }
              }
            } else {
              try {
                // const application =applicationName.split(' ').join('+');
                await fetchWithTimeout(
                  `${process.env.REACT_APP_BACKEND_URI}/getdata?applicationName=${encodeURIComponent(
                    applicationName
                  )}&graph_access_token=${token}`,
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                  }
                )
                  .then((res) => res.json(), props.setProgress(10))
                  .then((response) => {
                    setApplicationId("");
                    setApplicationManagerDisplay(true);
                    const data = response.data;
                    const appStatus = response.status;
                    const ownerStatus = response.owner;
                    const appEnv = response.app_env;
                    console.log(appEnv);
                    setApplicationEnv(appEnv);
                    setShowOwner(ownerStatus);
                    if (ownerStatus == false) {
                      toast.warning(
                        "Restricted: Application has not CI owner assigned",
                        {
                          position: "top-center",
                          autoClose: 3000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "light",
                        }
                      );
                    }
                    if (data.length === 0 || data.includes("Production")) {
                      if (appStatus === "retired") {
                        setshowcreatebutton(false);
                        setIsRetired(true);
                        toast.warning(
                          applicationName +
                            " is Retired. Showing info in read-only mode.",
                          {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                          }
                        );
                      } else {
                        setIsRetired(false);
                      }
                      toastMessage =
                        data.length === 0
                          ? "No Relationship Exist"
                          : "App is retired";
                      if (data.includes("Production")) {
                        toastMessage = data;
                        setshowcreatebutton(false);
                      }
                      if (toastMessage === "App is retired") {
                        setshowcreatebutton(false);
                      }
                      if (
                        toastMessage === "No Relationship Exist" &&
                        appStatus !== "retired"
                      ) {
                        setshowcreatebutton(true);
                      }
                      toast.warning(toastMessage, {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                      });
                      setshowTable(false);
                      setTable1([]);
                    } else {
                      setshowcreatebutton(true);
                      if (appStatus === "retired") {
                        setshowcreatebutton(false);
                        setIsRetired(true);
                        toast.warning(
                          applicationName +
                            " is Retired. Showing info in read-only mode.",
                          {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                          }
                        );
                      } else {
                        setIsRetired(false);
                      }
                      setTable1(data);
                      setshowTable(true);
                    }
                    //console.log(data)
                  });
                props.setProgress(70);
                //   var temp =await [...new Set(table1.map(row => row[2]))];
                //  await setuniqueValues(temp)
                //   console.log(uniqueValues)
                props.setProgress(100);
                await getID(applicationName);
                await fetchData();
                await showRate();
                await setTabIndex(0);

                // sessionStorage.setItem("table",JSON.stringify(table1))
                // console.log(table1[1].indexOf(applicationName))
              } catch (error) {
                if (error.message === "Timeout") {
                  toast.error("Request Timed Out", {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                } else {
                  console.log(error);
                  setshowcreatebutton(false);
                  toast.error(
                    `Record not found in ${relationshipCategorySelected.label}. App names are case sensitive, please select from below suggestions.`,
                    {
                      position: "top-center",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                    }
                  );
                }
                try {
                  const response = await fetchWithTimeout(
                    `${process.env.REACT_APP_BACKEND_URI}/relevantSuggestion`,
                    {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem(
                          "token"
                        )}`,
                      },
                      body: JSON.stringify({
                        applicationName: applicationName,
                      }),
                    }
                  );
                  if (response.ok) {
                    const result = await response.json();
                    const relevant_application =
                      result.relevant_application_names;
                    setSuggestionList(relevant_application);
                    setDisplaySuggestion(true);
                  } else {
                    console.error("Failed to fetch data");
                  }
                } catch (error) {
                  console.error("An error occurred:", error);
                  toast.error("Failed to fetch Suggestions Badge", {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                }
              }
              // console.log("After return");
              props.setProgress(100);
              // console.log(token);

              await handleShowSubscribe(toastMessage);
              await handleShowCertify(toastMessage);
            }
          }
          if (relationshipCategorySelected.value == "app_to_app") {
            if (/^[Aa]\d{2,}$/.test(applicationName)) {
              toast.error(
                "Search by App ID is only available for App to Server",
                {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                }
              );

              props.setProgress(100);
            } else {
              setTable5([]);
              setAppData([]);
              try {
                await fetch(
                  `${process.env.REACT_APP_BACKEND_URI}/getappdata?applicationName=${encodeURIComponent(
                    applicationName
                  )}&graph_access_token=${token}`,
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                  }
                )
                  .then((res) => res.json(), props.setProgress(10))
                  .then((response) => {
                    const data = response.data;
                    const appStatus = response.status;
                    const ownerStatus = response.owner;
                    setShowOwner(ownerStatus);
                    if (ownerStatus == false) {
                      toast.warning(
                        "Restricted: Application has not CI owner assigned",
                        {
                          position: "top-center",
                          autoClose: 3000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "light",
                        }
                      );
                    }
                    console.log("Data", data);
                    if (data.length === 0 || data.includes("Production")) {
                      if (appStatus === "retired") {
                        setshowcreatebutton(false);
                        setIsRetired(true);
                        toast.warning(
                          applicationName +
                            " is Retired. Showing info in read-only mode.",
                          {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                          }
                        );
                      } else {
                        setIsRetired(false);
                      }
                      toastMessage =
                        data.length === 0
                          ? "No Relationship Exist"
                          : "App is retired";
                      if (data.includes("Production")) {
                        toastMessage = data;
                        setshowcreatebutton(false);
                        setTable5([]);
                      }
                      if (toastMessage === "App is retired") {
                        setshowcreatebutton(false);
                      }
                      if (toastMessage === "No Relationship Exist") {
                        setshowcreatebutton(true);
                      }
                      toast.warning(toastMessage, {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                      });
                      setTable5([]);
                    } else {
                      setshowcreatebutton(true);
                      if (appStatus === "retired") {
                        setshowcreatebutton(false);
                        setIsRetired(true);
                        toast.warning(
                          applicationName +
                            " is Retired. Showing info in read-only mode.",
                          {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                          }
                        );
                      } else {
                        setIsRetired(false);
                      }
                      setTable5(data);
                      console.log("Test Table5", table5);
                    }
                    //console.log(data)
                  });
                props.setProgress(70);
                //   var temp =await [...new Set(table1.map(row => row[2]))];
                //  await setuniqueValues(temp)
                //   console.log(uniqueValues)
                props.setProgress(100);
                await getID(applicationName);
                await fetchAppData();
                await showRate();
              } catch (error) {
                console.log(error);
                toast.error(
                  `Record not found in ${relationshipCategorySelected.label}. App names are case sensitive, please select from below suggestions.`,
                  {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  }
                );
                try {
                  const response = await fetchWithTimeout(
                    `${process.env.REACT_APP_BACKEND_URI}/relevantSuggestion`,
                    {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem(
                          "token"
                        )}`,
                      },
                      body: JSON.stringify({
                        applicationName: applicationName,
                      }),
                    }
                  );
                  if (response.ok) {
                    const result = await response.json();
                    const relevant_application =
                      result.relevant_application_names;
                    setSuggestionList(relevant_application);
                    setDisplaySuggestion(true);
                  } else {
                    console.error("Failed to fetch data");
                  }
                } catch (error) {
                  console.error("An error occurred:", error);
                  toast.error("Failed to fetch Suggestions Badge", {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                }
              }
              props.setProgress(100);

              await handleShowSubscribe(toastMessage);
              await handleShowCertify(toastMessage);
            }
          }
          if (relationshipCategorySelected.value == "app_to_cloud") {
            if (/^[Aa]\d{2,}$/.test(applicationName)) {
              toast.error(
                "Search by App ID is only available for App to Server",
                {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                }
              );

              props.setProgress(100);
            } else {
              setShowTooltip("");
              await setTabIndex(0);
              await handleShowSubscribe(toastMessage);
              await toast.promise(fetchCloudData(), {
                pending: "Fetching Data",
              });
            }
          }
          if (relationshipCategorySelected.value == "server_to_app") {
            setTable8([]);
            setServerData([]);
            setSelectedServerOption([]);
            await setTabIndex(0);
            setInputserverName(
              inputserverName
                .replace(/\s+/g, "") // Remove all whitespace characters (including tab spaces)
                .split(",")
                .slice(0, 20)
                .join(",")
            );
            setShowCertify(false);
            setShowSaaS(false);
            setShowSubscribe(false);
            setShowUnsubscribe(false);
            try {
              // const application =applicationName.split(' ').join('+');
              const response = await fetchWithTimeout(
                `${process.env.REACT_APP_BACKEND_URI}/showservertoapp`,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  },
                  body: JSON.stringify({
                    Servername: inputserverName,
                  }),
                }
              );
              if (inputserverName.trim() == "") {
                toast.error("Server Name Cannot Be Empty", {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
                props.setProgress(100);
                setshowCommit(false);
              } else {
                if (response.ok) {
                  const result = await response.json();
                  console.log(result);
                  props.setProgress(70);
                  const applicationNameList = inputserverName.split(",");
                  const uniqueApplicationNameList = Array.from(
                    new Set(applicationNameList)
                  );
                  if (result.length === 0) {
                    setshowcreatebutton(false);
                    if (uniqueApplicationNameList.length < 2) {
                      toast.warning(inputserverName + " is invalid server", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                      });
                    } else {
                      toast.warning(inputserverName + " are invalid servers", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                      });
                    }
                    // setshowcreatebutton(true);
                    setTable8([]);
                  } else {
                    setTable8(result);
                    setshowcreatebutton(true);
                  }
                } else {
                  console.error("Failed to fetch data");
                }

                props.setProgress(100);
                await showRate();
                await setTabIndex(0);
                await fetchServerData();
              }

              // sessionStorage.setItem("table",JSON.stringify(table1))
              // console.log(table1[1].indexOf(applicationName))
            } catch (error) {
              if (error.message === "Timeout") {
                toast.error("Request Timed Out", {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              } else {
                console.log(error);
                toast.error(`Failed to fetch Server to App data`, {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              }
            }
            // console.log("After return");
            props.setProgress(100);

            // console.log(token)
          }
          if (relationshipCategorySelected.value == "app_to_repository") {
            setShowTooltip("");
            setIsButtonDisabled(true);
            try {
              await setTabIndex(0);
              await toast.promise(fetchRepoData(), {
                pending: "Loading Repositories Data",
              });
              await handleShowSubscribe(toastMessage);
            } finally {
              setIsButtonDisabled(false);
            }
          }
        }
      }
    } else {
      toast.error("Please select an application from the drop down", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setIsButtonDisabled(false);

  };

  useEffect(() => {
    if (
      relationshipCategorySelected.label == "Server to App" &&
      inputserverName.trim() != "" &&
      table8.length > 0
    ) {
      const applicationNameList = inputserverName
        .split(",")
        .map((name) => name.trim().replace(/\s+/g, ""))
        .filter((name) => name !== "");
      const uniqueApplicationNameList = Array.from(
        new Set(applicationNameList)
      );

      let validServer = "";
      let invalidServer = "";
      const newServerOptions = [];
      for (let i = 0; i < uniqueApplicationNameList.length; i++) {
        if (indexOf2d(table8, uniqueApplicationNameList[i])) {
          if (validServer == "") {
            validServer += uniqueApplicationNameList[i];
          } else {
            validServer += "," + uniqueApplicationNameList[i];
          }
          newServerOptions.push({
            value: uniqueApplicationNameList[i],
            label: uniqueApplicationNameList[i],
          });
        } else {
          if (invalidServer == "") {
            invalidServer += uniqueApplicationNameList[i];
          } else {
            invalidServer += "," + uniqueApplicationNameList[i];
          }
        }
      }
      console.log("applicationNameList", applicationNameList);
      console.log("uniqueApplicationNameList", uniqueApplicationNameList);
      console.log("ServerOptions", newServerOptions);
      setServerOptions(newServerOptions);
      if (invalidServer != "") {
        if (invalidServer.includes(",")) {
          toast.warning(invalidServer + " are invalid servers", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.warning(invalidServer + " is invalid servers", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      }
      setInputserverName(validServer);
    }
  }, [table8]);

  /**
   * Handles the submit event when creating a new entry.
   * @param {Event} e - The submit event object.
   * @returns {Promise<void>} - A promise that resolves when the submit event is handled.
   */
  const handleCreateSubmit = async (e) => {
    let serverswithoutenv = {};
    setServers({});
    // setshowCommit(true)
    props.setProgress(50);
    e.preventDefault();
    setServers({});
    console.log("Server", serverName);
    // const stringWithoutSpecialChars = serverName.replace(/[^a-zA-Z0-9,]/g, '');
    // console.log("Server without special characters", stringWithoutSpecialChars);
    // const stringWithoutSpaces = stringWithoutSpecialChars.replace(/\s/g, '');
    // console.log("Server withot space",stringWithoutSpaces)
    // const ServerListWithoutSpaces = stringWithoutSpaces.split(',');
    // const ServerList = ServerListWithoutSpaces.filter(item => item !== '');
    const ServerList = serverName
      // .replace(/[^a-zA-Z0-9,]/g, "")
      .split(",")
      .filter((item) => item.trim() !== "");
    console.log("Server List", ServerList);
    let sucess_entry = 0;
    if (ServerList.length > 0) {
      if (/^[Aa]\d{2,}$/.test(applicationName)) {
        for (let i = 0; i < Math.min(ServerList.length, 10); i++) {
          if (
            indexOf2d_appID(
              table1,
              ServerList[i],
              applicationNameSelected.value
            )
          ) {
            console.log("Relationship exists");
            toast.error(ServerList[i] + " Relationship Exists", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            props.setProgress(100);
          } else {
            console.log("else part");
            props.setProgress(50);
            // const application =applicationName.split(' ').join('+');
            try {
              const response = await fetchWithTimeout(
                `${process.env.REACT_APP_BACKEND_URI}/create?applicationName=${encodeURIComponent(
                  applicationNameSelected.value
                )}&serverName=${encodeURIComponent(
                  ServerList[i]
                )}&requestedby=${
                  graphData.displayName
                }&graph_access_token=${token}`,
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  },
                }
              );
              props.setProgress(70);
              const data = await response.json();
              props.setProgress(100);
              console.log(data.message);
              if (data.message == "Data processed successfully") {
                toast.success(ServerList[i] + " processed successfully", {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
                sucess_entry += 1;
              } else if (data.message.includes("Request for relation")) {
                console.log(ServerList[i] + " Relationship exists");
                toast.error(data.message, {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
                props.setProgress(100);
              } else if (data?.message.includes("Exception: No Owner")) {
                toast.error(
                  "Restricted: Application has not CI owner assigned",
                  {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  }
                );
                props.setProgress(100);
              } else if (data.message.includes("Server Env Not Found")) {
                toast.warning(ServerList[i] + " " + data.message, {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });

                // setServers((prevServers) => ({
                //   ...prevServers,
                //   [ServerList[i]]: ""
                // }));
                serverswithoutenv[ServerList[i]] = "";
              } else {
                toast.warning(ServerList[i] + " " + data.message, {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              }
              console.log("Sucessfull Entry", i, sucess_entry);
            } catch (error) {
              if (error.message === "Timeout") {
                toast.error("Request Timed Out", {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              } else {
                toast.error("Server Error", {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              }
            }
            var temp = await [...new Set(table1.map((row) => row[2]))];
            await setuniqueValues(temp);
            console.log(uniqueValues);
          }
          if (i === ServerList.length - 1 && sucess_entry > 0) {
            toast.info("You can confirm your changes", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setshowCommit(true);
          }
        }
      } else {
        for (let i = 0; i < Math.min(ServerList.length, 10); i++) {
          if (indexOf2d(table1, ServerList[i])) {
            console.log("Relationship exists");
            toast.error(ServerList[i] + " Relationship Exists", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            props.setProgress(100);
          } else {
            console.log("else part");
            props.setProgress(50);
            // const application =applicationName.split(' ').join('+');
            try {
              const response = await fetchWithTimeout(
                `${process.env.REACT_APP_BACKEND_URI}/create?applicationName=${encodeURIComponent(
                  applicationName
                )}&serverName=${encodeURIComponent(
                  ServerList[i]
                )}&requestedby=${
                  graphData.displayName
                }&graph_access_token=${token}`,
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  },
                }
              );
              props.setProgress(70);
              const data = await response.json();
              props.setProgress(100);
              console.log(data.message);
              if (data.message == "Data processed successfully") {
                toast.success(ServerList[i] + " processed successfully", {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
                sucess_entry += 1;
              } else if (data.message.includes("Request for relation")) {
                console.log(ServerList[i] + " Relationship exists");
                toast.error(data.message, {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
                props.setProgress(100);
              } else if (data?.message.includes("Exception: No Owner")) {
                toast.error(
                  "Restricted: Application has not CI owner assigned",
                  {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  }
                );
                props.setProgress(100);
              } else if (data?.message.includes("Server Env Not Found")) {
                console.log("Server Env Not Found");
                toast.warning(ServerList[i] + " " + data.message, {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });

                // await setServers((prevServers) => ({
                //   ...prevServers,
                //   [ServerList[i]]: ""
                // }));
                serverswithoutenv[ServerList[i]] = "";
                console.log(servers);
                console.log(Object.keys(servers).length);
              } else {
                toast.warning(ServerList[i] + " " + data.message, {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              }
              console.log("Sucessfull Entry", i, sucess_entry);
            } catch (error) {
              if (error.message === "Timeout") {
                toast.error("Request Timed Out", {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              } else {
                toast.error("Server Error", {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              }
            }
            //   var temp =await [...new Set(table1.map(row => row[2]))];
            //  await setuniqueValues(temp)
            //   console.log(uniqueValues)
          }
          if (i === ServerList.length - 1 && sucess_entry > 0) {
            toast.info("You can confirm your changes", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setshowCommit(true);
          }
        }
      }
    } else if (ServerList.length == 0) {
      toast.error("Server Name Cannot Be Empty", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      props.setProgress(100);
    }
    console.log("Servers", Object.keys(servers).length);
    for (let key in servers) {
      console.log(key);
      if (servers.hasOwnProperty(key)) {
        console.log(servers[key]);
        break;
      }
    }
    // setopenserverenvmodal(true);
    //   if (Object.keys(servers).length > 0) {
    //     setopenserverenvmodal(true);
    // }

    setServers(serverswithoutenv);
    setserverName("");
  };
  /**
   * Fetches the application ID and owner from the server.
   * @async
   * @function getID
   * @returns {Promise<void>}
   */
  const getID = async () => {
    await fetchWithTimeout(
      `${process.env.REACT_APP_BACKEND_URI}/getApplicationId?applicationName=${encodeURIComponent(
        applicationName
      )}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setApplicationId(data.applicationId);
        setApplicationOwner(data.applicationOwner);
      })
      .catch((error) => {
        console.error("Error fetching Application ID:", error);
      });
  };
  useEffect(() => {
    props.setProgress(90);
    props.setProgress(100);
    getDimensions();
    if (autosearch && applicationName !== "") {
      inputRef.current.click();
      setautosearch(false);
    }
    // inputRef.current.click();
    // setTimeout(() => {
    //   getToken()
    // }, 2000);
    // getToken()
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      getDimensions();
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    console.log(graphData);
  }, []);
  // const getTableData=async ()=>{
  //   // getSuggestions(applicationName);
  //   if(sessionStorage.getItem("table")!==null){
  //     const data=await JSON.parse(sessionStorage.getItem("table"))
  //     await setTable1(data)
  //     console.log(table1)
  //     setshowTable(true)
  //   }
  // }
  // const getSuggestions = async (applicationName) => {
  //   const response = await fetchWithTimeout(`${process.env.REACT_APP_BACKEND_URI}/?applicationName=${applicationName}`);
  //   const result = await response.json();
  //   await setSuggestions(result);
  //   console.log(suggestions)
  // }
  function indexOf2d(array2d, itemtofind) {
    return (
      [].concat
        .apply([], [].concat.apply([], array2d))
        .map((item) => item.toLowerCase())
        .indexOf(itemtofind.toLowerCase()) !== -1
    );
  }

  function indexOf2d_appID(array2d, itemtofind, itemtofind2) {
    const itemtofind2Str = itemtofind2.toString();
    console.log("Item to find 2", itemtofind2);
    console.log("Array2d", array2d);
    return array2d.some(
      (row) =>
        row
          .map((item) => (item ? item.toLowerCase() : ""))
          .includes(itemtofind.toLowerCase()) &&
        row
          .map((item) => (item ? item.toLowerCase() : ""))
          .includes(itemtofind2Str.toLowerCase())
    );
  }

  function searchValuesInRow(table, valuea, valueb) {
    console.log("Table", table, valuea, valueb);
    valuea = valuea.toLowerCase();
    valueb = valueb.toLowerCase();
    return table.some(
      (row) =>
        row.map((cell) => cell.toLowerCase()).includes(valuea) &&
        row.map((cell) => cell.toLowerCase()).includes(valueb)
    );
  }
  /**
   * Handles the commit action by sending a POST request to the server.
   * Updates the progress, displays toast messages based on the response,
   * and triggers additional actions if necessary.
   * @returns {Promise<void>} A promise that resolves when the commit action is completed.
   */
  const handleCommit = async () => {
    props.setProgress(30);
    try {
      const response = await fetchWithTimeout(`${process.env.REACT_APP_BACKEND_URI}/commit`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          requested_by: graphData.displayName,
          applicationName:
            relationshipCategorySelected.value === "server_to_app"
              ? inputserverName
              : applicationName,
          relationshipCategory: relationshipCategorySelected.label,
        }),
      });
      props.setProgress(70);
      const result = await response.json();
      if (result.includes("Success")) {
        await fetchOwnership();
        toast.success("Confirmed sucessfully", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setshowCommit(false);
        setserverName("");
        setDisableCertify(true);
        await fetchData();
        await fetchAppData();
        await fetchServerData();
      } else if (result.includes("Exception: No Owner")) {
        toast.error("Restricted: Application has not CI owner assigned", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setshowCommit(false);
      } else {
        toast.error("Confirm Failed", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }

      props.setProgress(100);
      setTimeout(() => {
        if (!rated) {
          props.setOpen(true);
          setRated(true);
        }
      }, 2000);
    } catch (error) {
      if (error.message === "Timeout") {
        toast.error("Request Timed Out", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error("Server Error", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
    await fetchData();
    await fetchAppData();
  };
  const handleSuggestionBadge = async (item) => {
    await setSuggestionSelected(true);
    await setapplicationName(item.item);
    // await handleSearchSubmit(new Event('submit',));
    inputRef.current.click();
    setDisplaySuggestion(false);
  };
  /**
   * Fetches the rate from the server and updates the state accordingly.
   * @async
   * @function showRate
   * @returns {Promise<void>}
   */
  const showRate = async () => {
    try {
      const response = await fetchWithTimeout(
        `${process.env.REACT_APP_BACKEND_URI}/showrate`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({ user: graphData?.displayName }),
        }
      );
      if (response.ok) {
        const result = await response.json();
        console.log("Rate Result", result);
        if (result === "Success") {
          console.log("IF CONDITION");
          setRated(false);
        } else {
          console.log("ELSE CONDITION");
          setRated(true);
        }
        console.log("Rate Value", rated);
      }
    } catch (error) {
      if (error.message === "Timeout") {
        toast.error("Request Timed Out", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        console.error("An error occurred:", error);
        toast.error("Show Rate Error", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };
  /**
   * Handles the certification process.
   * @async
   * @function handlecertify
   * @returns {Promise<void>}
   */
  const handlecertify = async () => {
    try {
      const response = await fetchWithTimeout(`${process.env.REACT_APP_BACKEND_URI}/certify`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          applicationName: applicationName,
          logged_in_user: graphData.displayName,
          relationship_category: relationshipCategorySelected.value,
        }),
      });
      const data = await response.json();
      if (
        data.message === "Certified" &&
        !/^[Aa]\d{2,}$/.test(applicationName)
      ) {
        await fetchOwnership();
        toast.info(applicationName + " - " + data.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setShowCertify(false);
      } else if (/^[Aa]\d{2,}$/.test(applicationName)) {
        toast.info(data.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setShowCertify(false);
      }

      // setCertificationSuccessful(true);
      else if (data.message === "Invalid relationship category") {
        toast.error("Please select a relationship category", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        console.error("Failed to fetch certify");
      }
    } catch (error) {
      if (error.message === "Timeout") {
        toast.error("Request Timed Out", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        console.error("An error occurred:", error);
        toast.error("Certify Error", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  const handlemarkassaas = async () => {
    try {
      const response = await fetchWithTimeout(`${process.env.REACT_APP_BACKEND_URI}/markassaas`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          applicationName: applicationName,
          username: graphData?.displayName,
        }),
      });
      const data = await response.json();
      if (
        data === "Success" &&
        !/^[Aa]\d{2,}$/.test(applicationName)
      ) {
        toast.info(applicationName + " - Successfully marked as SaaS", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setDisableSaaS(true);
        setShowSaaSTooltip("Application is already marked as SaaS");
        setCertificationSuccessful(false);
      } else if (data === "Success" && /^[Aa]\d{2,}$/.test(applicationName)) {
        toast.info("Application(s) Successfully marked as SaaS", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setDisableSaaS(true);
        setShowSaaSTooltip("All applications you own are marked as SaaS");
      } else {
        console.error("Failed to mark app as SaaS");
      }
    } catch (error) {
      if (error.message === "Timeout") {
        toast.error("Request Timed Out", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        console.error("An error occurred:", error);
        toast.error("Error while marking as SaaS", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  const handleunmarkassaas = async () => {
    try {
      const response = await fetchWithTimeout(`${process.env.REACT_APP_BACKEND_URI}/unmarkassaas`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          applicationName: applicationName,
          username: graphData?.displayName,
        }),
      });
      const data = await response.json();
      if (
        data === "Success" &&
        !/^[Aa]\d{2,}$/.test(applicationName)
      ) {
        toast.info(applicationName + " - Successfully unmarked as SaaS", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setDisableSaaS(false);
        setShowSaaSTooltip("Certify that your app is a SaaS application");
        setCertificationSuccessful(false);
      } else if (data === "Success" && /^[Aa]\d{2,}$/.test(applicationName)) {
        toast.info("Application(s) Successfully unmarked as SaaS", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setDisableSaaS(false);
        setShowSaaSTooltip("Certify that the apps you own under this App ID are SaaS applications");
      } else {
        console.error("Failed to mark app as SaaS");
      }
    } catch (error) {
      if (error.message === "Timeout") {
        toast.error("Request Timed Out", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        console.error("An error occurred:", error);
        toast.error("Error while marking as SaaS", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  const handleSaaSClick = async () => {
    if (!disableSaaS) {
      await toast.promise(handlemarkassaas(), {
        pending: "Marking Application as SaaS",
      });
    } else {
      await toast.promise(handleunmarkassaas(), {
        pending: "Unmarking Application as SaaS",
      });
    }
  };

  /**
   * Handles the subscription process.
   * @async
   * @function handleSubscribe
   * @returns {Promise<void>}
   */
  const handleSubscribe = async () => {
    try {
      const response = await fetchWithTimeout(
        `${process.env.REACT_APP_BACKEND_URI}/subscribe`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            app_name: applicationName,
            email: graphData?.mail,
          }),
        }
      );
      const result = await response.json();
      if (result === "Subscription Successful") {
        toast.success(`${applicationName} - Subscribed Successfully`, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setShowSubscribe(false);
        setShowUnsubscribe(true);
      } else {
        toast.error("Subscription failed", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.error("Error subscribing:", error);
      toast.error("Subscription failed", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  /**
   * Handles the unsubscription process.
   * @async
   * @function handleUnsubscribe
   * @returns {Promise<void>}
   */
  const handleUnsubscribe = async () => {
    try {
      const response = await fetchWithTimeout(
        `${process.env.REACT_APP_BACKEND_URI}/unsubscribe`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            app_name: applicationName,
            email: graphData?.mail,
          }),
        }
      );
      const result = await response.json();
      if (result === "Unsubscription Successful") {
        toast.success(`${applicationName} - Unsubscribed Successfully`, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setShowSubscribe(true);
        setShowUnsubscribe(false);
      } else {
        toast.error("Unsubscription failed", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.error("Error unsubscribing:", error);
      toast.error("Unsubscription failed", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  const getDimensions = () => {
    if (window.innerWidth == 2048) {
      setWidth("126px");
    }
    if (window.innerWidth == 1920) {
      setWidth("63px");
    }
    if (window.innerWidth == 1707) {
      setWidth("33px");
    }
    if (window.innerWidth == 1536) {
      return "31px";
    }
  };
  const handleRelationshipCategory = async (option) => {
    setTabIndex(0);
    console.log("option", option);
    console.log("relationshipCategorySelected", relationshipCategorySelected);
    if (
      option.value === "server_to_app" ||
      relationshipCategorySelected.value === "server_to_app"
    ) {
      console.log("INSIDE IF");
      setautosearch(false);
      await setTabIndex(0);
      // setapplicationName("");
      // setInputserverName("");
      if (option.value === "server_to_app") {
        setCertificationSuccessful(false);
        setShowCertify(false);
        setShowSaaS(false);
        setShowSubscribe(false);
        setShowUnsubscribe(false);
        setShowSaaS(false);
        const suggestionBox = document.getElementById("suggestion-box");
        suggestionBox.style.display = "none";
        setSelectedServerOption([]);
      }
    } else if (
      /^[Aa]\d{2,}$/.test(applicationName) &&
      (option.value == "app_to_app" || option.value == "app_to_cloud" || option.value == "app_to_repository")
    ) {
      console.log("INSIDE app to app");
      await setautosearch(false);
      await setapplicationName("");
      console.log("autosearch", autosearch);
    } else {
      console.log("INSIDE ELSE");
      setautosearch(true);
    }
    setautosearch(false);

    await setRelationshipCategorySelected(option);
    // if (option.value === "app_to_server") {
    //   setTabIndex(0);
    // }

    if (
      applicationName !== "" &&
      option.value !== "server_to_app" &&
      relationshipCategorySelected.value !== "server_to_app"
    ) {
      console.log(/^[Aa]\d{2,}$/.test(applicationName));
      console.log(option.value);

      if (
        /^[Aa]\d{2,}$/.test(applicationName) &&
        (option.value == "app_to_app" || option.value == "app_to_cloud")
      ) {
        console.log("Inside app to app");
      } else {
        inputRef?.current.click();
      }
      console.log(`Option selected:`, option.label);
      console.log(/^[Aa]\d{2,}$/.test(applicationName));
    }
  };
  const handleRelationshipType = (option) => {
    setRelationshipType(option);
  };
  const handleTabClick = (index) => {
    setTabIndex(index);
    // setRepositoryName("");
  };
  const handleCreateApplicationSubmit = async (e) => {
    // setshowCommit(true)
    e.preventDefault();
    if (selectedCreateSuggestion) {
      props.setProgress(50);
      console.log("Child Application", applicationinputValue);
      if (applicationinputValue.length == 0) {
        toast.error("Application Name Cannot Be Empty", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        props.setProgress(100);
      } else if (
        relationshipTypeSelected.value == "" ||
        relationshipTypeSelected.value == null
      ) {
        toast.error("Please select a relationship type", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        props.setProgress(100);
      } else {
        if (
          searchValuesInRow(
            table5,
            applicationinputValue,
            relationshipTypeSelected.value
          )
        ) {
          console.log("Relationship exists");
          toast.error(
            applicationinputValue +
              " for " +
              relationshipTypeSelected.value +
              " Relationship Exists",
            {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
          props.setProgress(100);
        } else {
          console.log("else part");
          props.setProgress(50);
          // const application =applicationName.split(' ').join('+');
          try {
            const response = await fetchWithTimeout(
              `${process.env.REACT_APP_BACKEND_URI}/apptoappcreate`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                body: JSON.stringify({
                  applicationName: applicationName,
                  serverName: applicationinputValue,
                  requestedby: graphData?.displayName,
                  relationtype: relationshipTypeSelected.value,
                }),
              }
            );
            props.setProgress(70);
            const data = await response.json();
            props.setProgress(100);
            console.log(data.message);
            if (data.message == "Data processed successfully") {
              toast.success(applicationinputValue + " processed successfully", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });

              toast.info("You can confirm your changes", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              setshowCommit(true);
              setapplicationInputValue("");
            } else if (data.message.includes("Request for relation")) {
              console.log(applicationinputValue + " Relationship exists");
              toast.error(data.message, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              props.setProgress(100);
            } else if (data?.message.includes("Restricted:")) {
              toast.error(data.message, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              props.setProgress(100);
            } else {
              toast.warning(applicationinputValue + " " + data.message, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          } catch (error) {
            if (error.message === "Timeout") {
              toast.error("Request Timed Out", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            } else {
              console.log("APp to app create error", error);
              toast.error("Server Error", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          }
          //   var temp =await [...new Set(table1.map(row => row[2]))];
          //  await setuniqueValues(temp)
          //   console.log(uniqueValues)
        }
      }
    } else {
      toast.error("Please select an application from the drop down", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleSubscriptionNameSubmit = async (e) => {
    e.preventDefault();
    if (subscriptionName.length == 0) {
      toast.error("Subscription Name Cannot Be Empty", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      props.setProgress(20);

      await toast.promise(
        (async () => {
          try {
            const response = await fetch(
              `${process.env.REACT_APP_BACKEND_URI}/createclouddata?subscriptionName=${encodeURIComponent(
                subscriptionName
              )}&applicationName=${encodeURIComponent(
                applicationName
              )}&username=${encodeURIComponent(graphData?.displayName)}`,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              }
            );
            props.setProgress(50);
            const data = await response.json();
            setCloudData(data);
            if (
              data?.resourceGroups.length === 0 &&
              data?.certified_data.length === 0
            ) {
              toast.error("No data found for the application", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              console.log("No data found for the application");
              props.setProgress(100);
              if (data?.isciowner === true) {
                setshowcreatebutton(true);
                console.log("true");
              } else if (data?.isciowner === false) {
                console.log("false");
                setshowcreatebutton(false);
              }
              return;
            }
            if (data?.certificationDetails.includes("Certified")) {
              setCertificationSuccessful(true);
              setCertificationResult(data?.certificationDetails);
            }
            if (data?.isciowner === true) {
              setshowcreatebutton(true);
              console.log("true");
            } else if (data?.isciowner === false) {
              console.log("false");
              setshowcreatebutton(false);
            }
            props.setProgress(100);
          } catch (error) {
            // alert("Error fetching data");

            props.setProgress(100);
            throw error; // Rethrow the error to be caught by toast.promise error handler
          }
        })(),
        {
          pending: "Fetching data",
          error: "Incorrect Subscription Name",
        }
      );
    }
  };

  const handleServerCreateSubmit = async (e) => {
    // setshowCommit(true)
    e.preventDefault();
    if (selectedCreateSuggestion) {
      props.setProgress(50);
      console.log("Child Application", applicationinputValue);
      if (applicationinputValue.length == 0) {
        toast.error("Application Name Cannot Be Empty", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        props.setProgress(100);
      } else {
        props.setProgress(50);
        let sucess_entry = 0;
        let serverswithoutenv = {};

        for (let i = 0; i < selectedServerOption.length; i++) {
          const option = selectedServerOption[i];

          if (searchValuesInRow(table8, option.value, applicationinputValue)) {
            console.log("Relationship exists");
            toast.error(
              `${applicationinputValue} - ${option.value} Relationship Exists`,
              {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              }
            );
            props.setProgress(100);
          } else {
            props.setProgress(50);
            // const application =applicationName.split(' ').join('+');
            try {
              const response = await fetchWithTimeout(
                `${process.env.REACT_APP_BACKEND_URI}/create?applicationName=${encodeURIComponent(
                  applicationinputValue
                )}&serverName=${encodeURIComponent(option.value)}&requestedby=${
                  graphData.displayName
                }&graph_access_token=${token}`,
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  },
                }
              );

              props.setProgress(70);
              const data = await response.json();
              props.setProgress(100);
              console.log(data.message);

              if (data.message == "Data processed successfully") {
                toast.success(
                  `${applicationinputValue} - ${option.value}  processed successfully`,
                  {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  }
                );
                sucess_entry += 1;
              } else if (data.message.includes("Request for relation")) {
                console.log(
                  `${applicationinputValue} - ${option.value} Relationship exists`
                );
                toast.error(
                  `${applicationinputValue} - ${option.value} Relationship exists`,
                  {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  }
                );
              } else if (data?.message.includes("Exception: No Owner")) {
                toast.error(
                  "Restricted: Application has not CI owner assigned",
                  {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  }
                );
                props.setProgress(100);
              } else if (data.message.includes("Server Env Not Found")) {
                toast.warning(option.value + " " + data.message, {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });

                // setServers((prevServers) => ({
                //   ...prevServers,
                //   [ServerList[i]]: ""
                // }));
                serverswithoutenv[option.value] = "";

                const appenv = await data?.app_env;
                setApplicationEnv(appenv);
              } else {
                toast.warning(
                  `${applicationinputValue} - ${option.value}  ${data.message}`,
                  {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  }
                );
              }
            } catch (error) {
              if (error.message === "Timeout") {
                toast.error("Request Timed Out", {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              } else {
                toast.error("Server Error", {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              }
            }
          }
          //   var temp =await [...new Set(table1.map(row => row[2]))];
          //  await setuniqueValues(temp)
          //   console.log(uniqueValues)
          if (i === selectedServerOption.length - 1 && sucess_entry > 0) {
            toast.info("You can confirm your changes", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setshowCommit(true);
          }
        }
        props.setProgress(100);
        setServers(serverswithoutenv);
      }
    } else {
      toast.error("Please select an application from the drop down", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleRepositoryNameSubmit = async (e) => {
    e.preventDefault();
    if (repoInputValue.length == 0) {
      toast.error("Repository Name Cannot Be Empty", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else if (selectedSuggestion) {
      props.setProgress(20);
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URI}/createrepomapping`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            applicationName: applicationName,
            repositoryNames: repoInputValue,
            user_name: graphData?.displayName,
          }),
        });
    
        const data = await response.json();
    
        if (response.ok) {
          console.log("Repositories mapped successfully");
          console.log("Data", data);
          if (data?.certified_data.length === 0) {
            setTabIndex(1);
            if (data?.isciowner === true) {
              setshowcreatebutton(true);
            } else if (data?.isciowner === false) {
              setshowcreatebutton(false);
            }
            toast.warning(`No App to Repository Relationship Exists for ${applicationName}. Hence, showing suggestions.`, {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            if(Object.keys(data?.successfully_mapped).length > 0){
              toast.success(`Repositories Mapped Successfully to ${applicationName}`, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              if (data?.isciowner === true) {
                setshowcreatebutton(true);
              } else if (data?.isciowner === false) {
                setshowcreatebutton(false);
              }
              setRepoData([data]);
              setTabIndex(0);
            }
            if(Object.keys(data?.already_mapped).length > 0){
              const mapped_repos = Object.keys(data?.already_mapped).join(', ');
              toast.warning(`Relationship already exists between ${mapped_repos} and ${applicationName}`, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
            if(Object.keys(data?.not_found).length > 0){
              const absent_repos = Object.keys(data?.not_found).join(', ');
              toast.warning(`Repositories ${absent_repos} not found`, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          }
        } else {
          console.error("Error mapping repository:", data.error);
          toast.error("Failed to map repository", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } catch (error) {
        console.error("Request failed:", error);
        toast.error("Error mapping repository", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      setRepoInputValue("");
      props.setProgress(100);
    } else {
      toast.error("Please select a Repository from the drop down", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <div>
      <div
        className="create-form"
        id="createDiv"
        style={{
          paddingBottom:
            relationshipCategorySelected.value === "app_to_app"
              ? "75px"
              : "20px",
        }}
       >
        {relationshipCategorySelected.value == "app_to_server" && (
          <form
            className="create"
            onSubmit={async (e) => {
              await handleCreateSubmit(e);
              console.log("Servers", servers);
              // if(Object.keys(servers).length>0){
              //   console.log("Inside env modal if")
              //   setopenserverenvmodal(true)
              // }
            }}
          >
            <label htmlFor="fname">Application Name</label>
            {/^[Aa]\d{2,}$/.test(applicationName) ? (
              <Select
                id="applicationName"
                value={applicationNameSelected}
                options={
                  typeof appStatus === "object" && appStatus !== null
                    ? Object.keys(appStatus).map((key) => ({
                        value: key,
                        label: key,
                      }))
                    : []
                }
                onChange={(selected) => {
                  console.log(selected);
                  setapplicationNameSelected(selected);
                }}
                isClearable
                styles={{
                  container: (base) => ({ ...base, flex: 1 }),
                }}
              />
            ) : (
              <input
                type="text"
                id="fname"
                name="applicationName"
                value={applicationName}
                readOnly={true}
                placeholder=" "
                style={{ backgroundColor: "#D3D3D3" }} // Different background color
              />
            )}
            <label htmlFor="lname">Related To</label>
            <input
              type="text"
              id="lname"
              name="serverName"
              placeholder="Add upto 10 servers separated by comma"
              onChange={handleserverNameChange}
              value={serverName}
            />
            <input type="submit" value="Submit" />
            <button id="cancel" type="button" onClick={hideCreate}>
              Cancel
            </button>
          </form>
        )}
        {relationshipCategorySelected.value == "app_to_app" && (
          <form
            className="create"
            onSubmit={handleCreateApplicationSubmit}
            style={{
              width: "100%",
            }}
          >
            <label htmlFor="fname">Application Name</label>
            <input
              type="text"
              id="fname"
              name="applicationName"
              value={applicationName}
              readOnly={true}
              placeholder=" "
              style={{ backgroundColor: "#D3D3D3" }}
            />
            <label htmlFor="selectRelationshipType">Relationship Type:</label>
            <span
              style={{
                color: "red",
                marginLeft: "3px",
              }}
            >
              &#42;
            </span>
            <Select
              id="selectRelationshipType"
              value={relationshipTypeSelected}
              onChange={handleRelationshipType}
              options={relationshipType}
              isSearchable={false}
              isClearable={false}
              styles={{
                container: (base) => ({ ...base, flex: 1, zIndex: 2 }),
              }}
            />

            <label htmlFor="applicationInputName">Related To</label>
            <div style={{ width: "100%", position: "relative" }}>
              <input
                type="text"
                id="applicationInputName"
                name="applicationInputName"
                onChange={handleinputApplicationNameChange}
                value={applicationinputValue}
                style={{
                  marginBottom: "0px",
                  height: "38px",
                }}
              />
              <div
                id="suggestion-container2"
                style={{
                  width: "100%",
                  maxHeight: "100px",
                  backgroundColor: "white",
                  zIndex: 10,
                  position: "absolute",
                  display: "none",
                  borderTopLeftRadius: "0px",
                  overflowY: "auto",
                }}
              ></div>
            </div>
            <input type="submit" value="Submit" />
            <button id="cancel" type="button" onClick={hideCreate}>
              Cancel
            </button>
          </form>
        )}

        {relationshipCategorySelected.value == "app_to_cloud" && (
          <>
            <form className="create" onSubmit={handleSubscriptionNameSubmit}>
              <label htmlFor="fname">Application Name</label>
              <input
                type="text"
                id="fname"
                name="applicationName"
                value={applicationName}
                readOnly={true}
                placeholder=" "
                style={{ backgroundColor: "#D3D3D3" }}
              />
              <label htmlFor="lname">Subscription Name</label>
              <input
                type="text"
                id="lname"
                name="serverName"
                onChange={handleSubscriptionNameChange}
                value={subscriptionName}
              />
              <input type="submit" value="Submit" />
              <button id="cancel" type="button" onClick={hideCreate}>
                Cancel
              </button>
            </form>
          </>
        )}
        {relationshipCategorySelected.value == "server_to_app" && (
          <form
            className="create"
            onSubmit={handleServerCreateSubmit}
            style={{
              width: "100%",
            }}
          >
            <label htmlFor="fname">Server Name</label>
            {/* <input
              type="text"
              id="fname"
              name="applicationName"
              // value={applicationName}
              // readOnly={true}
              placeholder=" "
              // style={{ backgroundColor: "#D3D3D3" }}
            /> */}
            <Select
              isMulti
              id="selectServerOption"
              value={selectedServerOption}
              onChange={handleMultiSelectServerChange}
              options={serverOptions}
              isDisabled={serverOptions.length === 0}
              placeholder="Select servers..."
              styles={{ container: (base) => ({ ...base, flex: 1 }) }}
            />

            <label htmlFor="lname">Related To</label>
            <div style={{ width: "100%", position: "relative" }}>
              <input
                type="text"
                id="applicationInputName"
                name="applicationInputName"
                onChange={handleinputApplicationNameChange}
                value={applicationinputValue}
                style={{
                  marginBottom: "0px",
                  height: "38px",
                }}
              />
              <div
                id="suggestion-container2"
                style={{
                  width: "100%",
                  maxHeight: "100px",
                  backgroundColor: "white",
                  zIndex: 10,
                  position: "absolute",
                  display: "none",
                  borderTopLeftRadius: "0px",
                  overflowY: "auto",
                }}
              ></div>
            </div>
            <input type="submit" value="Submit" style={{ marginTop: "20px" }} />
            <button
              id="cancel"
              type="button"
              onClick={hideCreate}
              style={{ marginTop: "20px" }}
            >
              Cancel
            </button>
          </form>
        )}
        {relationshipCategorySelected.value === "app_to_repository" && (
          <>
              <form className="create" onSubmit={handleRepositoryNameSubmit}>
                  <label htmlFor="fname">Application Name</label>
                  <input
                      type="text"
                      id="fname"
                      name="applicationName"
                      value={applicationName}
                      readOnly={true}
                      placeholder=" "
                      style={{ backgroundColor: "#D3D3D3" }}
                  />
                  <label htmlFor="lname">Repository Name</label>
                  <input
                      style={{ width: "100%" }}
                      type="text"
                      name="repoName"
                      id="lname"
                      onChange={handleRepoInputChange}
                      value={repoInputValue}
                      placeholder={"Select a GitHub Repository"}
                      autoComplete="off"
                  />
                  <div className="suggestion-container2">
                    {currentRepoName.length >= 3 && (
                      <div id="suggestion-box3" className="suggestion-box2">
                        <div className="scrollable-suggestions" id="scrollable-suggestions">
                            {/* The checkboxes will be dynamically populated here */}
                        </div>
                        {repoSuggestionsAvailable && (
                          <button
                            id = "cancel"
                            type="button"
                            onClick={handleRepoSuggestionCancel}
                          >
                              Cancel
                          </button>
                        )}
                        {repoSuggestionsAvailable && (
                          <button
                            id = "done"
                            type="button"
                            onClick={handleDone}
                            disabled={tempSelectedRepos.length === 0}
                          >
                              Done
                          </button>
                        )}
                      </div>
                    )}  
                  </div>
                  <input type="submit" value="Submit" />
                  <button id="cancel" type="button" onClick={hideCreate}>
                      Cancel
                  </button>
              </form>
          </>
        )}
      </div>
      <div className="container1">
        <h2>Search For CI Relationship</h2>
      </div>
      <div className="search-form">
        <form onSubmit={handleSearchSubmit} style={{ marginTop: "30px" }}>
          <div
            style={{
              margin: "20px 0px",
              display: "flex",
              alignItems: "center",
              width: "475px",
            }}
          >
            <label
              htmlFor="selectRelationshipCategory"
              style={{ marginRight: "5px" }}
            >
              Relationship Category:
            </label>
            <Select
              id="selectRelationshipCategory"
              value={relationshipCategorySelected}
              onChange={handleRelationshipCategory}
              options={relationshipCategory}
              isSearchable={false}
              isClearable={false}
              styles={{
                container: (base) => ({ ...base, flex: 1, zIndex: 2 }),
              }}
            />
          </div>

          <label htmlFor="application-name">
            {" "}
            {relationshipCategorySelected.label === "Server to App"
              ? "Server Name:"
              : !applicationManagerDisplay
              ? "Application ID:"
              : "Application Name:"}{" "}
          </label>
          <div
            style={{
              display: "inline-flex",
              width: "300px",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
              <input
                style={{ width: "100%" }}
                className="search-input"
                type="text"
                name="applicationName"
                id="application-name"
                onChange={handleapplicationNameChange}
                value={
                  relationshipCategorySelected.value === "server_to_app"
                    ? inputserverName
                    : ((relationshipCategorySelected.value === "app_to_repository" && apptorepoflag))
                    ? searchbarName
                    : applicationName
                }
                // placeholder="Add upto 10 servers separated by comma"
                placeholder={
                  relationshipCategorySelected.label === "Server to App"
                    ? "Search upto 20 servers, comma-separated."
                    : (relationshipCategorySelected.label === "App to Server" || relationshipCategorySelected.label === "App to Repository")
                    ? "Application Name/ID"
                    : "Application Name"
                }
                autoComplete="off"
              />
              {relationshipCategorySelected.label === "App to Repository" && (
                <button
                  id="suggestion-button"
                  style={{
                    display: arrowStatus ? "block" : "none",
                    position: "absolute",
                    right: "0.5rem",
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    padding: "0",
                  }}
                  onClick={(event) => {
                    event.preventDefault();
                    const searchbarName =
                      document.getElementById("application-name").value;
                    const selectedAppId = searchbarName.substring(0, 5);
                    const suggestionBox = document.getElementById(
                      "suggestion-box"
                    );

                    // Toggle suggestion box visibility
                    if (suggestionBox.style.display === "block") {
                      suggestionBox.style.display = "none";
                    } else {
                      suggestionBox.style.display = "block";
                      suggestionBox.innerHTML = "";
                      handleSuggestionAppIdNames(selectedAppId, suggestionBox);
                    }
                  }}
                 >
                  <KeyboardArrowDownIcon
                    style={{
                      fontSize: "1.5rem",
                      color: "gray",
                    }}
                  />
                </button>
              )}
            </div>
            
            <div className="suggestion-container">
              <div
                style={{ width: "295px", position: "absolute" }}
                id="suggestion-box"
                className="suggestion-box"
              ></div>
            </div>
          </div>
          <input
            type="submit"
            style={{ backgroundColor: "#42B0D5" }}
            value="Search"
            ref={inputRef}
            disabled={isButtonDisabled}
          ></input>
          {!isRetired && certificationSuccessful && (
            <span
              data-tooltip-id="certified"
              data-tooltip-content={certificationResult}
              data-tooltip-place="top-start"
            >
              {/* <FcApproval
                style={{ marginLeft: "10px", fill: "#FFBF00" }}
                size={30}
              /> */}
              {showTooltip?.includes("Certification expire") ? (
                <FaExclamationCircle
                  size={20}
                  style={{
                    color: "#F28C28",
                    marginRight: "3px",
                    marginLeft: "5px",
                  }}
                />
              ) : (
                <FaCheckCircle
                  size={20}
                  style={{
                    color: "#198754",
                    marginLeft: "10px",
                  }}
                />
              )}
              <span style={{ marginLeft: "3px", fontSize: "13px" }}>
                {certificationResult}
              </span>
              {/* <Tooltip id="certified" /> */}
            </span>
          )}
        </form>

        <div></div>
        {subscriptionSuggestions.length > 0 &&
          relationshipCategorySelected.value == "app_to_cloud" && (
            <div
              style={{
                marginTop: "20px",
                fontSize: "1em",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span style={{ fontSize: "20px" }}>Suggested Subscriptions:</span>
              <select
                className="form-select"
                aria-label="Default select example"
                id="subscriptions"
                name="subscriptions"
                style={{
                  width: "fit-content",
                  padding: " 5px",
                  fontSize: "1rem",
                  marginLeft: "5px",
                  minWidth: "200px",
                }}
                onChange={async (e) => {
                  console.log("selected", e.target.value);
                  props.setProgress(20);
                  await toast
                    .promise(
                      async () => {
                        const response = await fetch(
                          `${process.env.REACT_APP_BACKEND_URI}/createclouddata?subscriptionName=${encodeURIComponent(
                            e.target.value
                          )}&applicationName=${encodeURIComponent(
                            applicationName
                          )}&username=${encodeURIComponent(
                            graphData?.displayName
                          )}`,
                          {
                            method: "GET",
                            headers: {
                              "Content-Type": "application/json",
                              Authorization: `Bearer ${localStorage.getItem(
                                "token"
                              )}`,
                            },
                          }
                        );
                        props.setProgress(50);
                        if (!response.ok) {
                          throw new Error("Failed to fetch cloud data");
                        }
                        const data = await response.json();
                        if (
                          data?.resourceGroups.length === 0 &&
                          data?.certified_data.length === 0
                        ) {
                          toast.error("No data found for the application", {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                          });
                          console.log("No data found for the application");
                          props.setProgress(100);
                          return;
                        }
                        setCloudData(data);
                        props.setProgress(100);
                        if (data?.certificationDetails.includes("Certified")) {
                          setCertificationSuccessful(true);
                          setCertificationResult(data?.certificationDetails);
                        }
                        if (data?.isciowner === true) {
                          setshowcreatebutton(true);
                          console.log("true");
                        } else if (data?.isciowner === false) {
                          console.log("false");
                          setshowcreatebutton(false);
                        }

                        return data; // Resolve the promise with data
                      },
                      {
                        pending: "Fetching data",
                        error: "Incorrect Subscription Name",
                      }
                    )
                    .catch((error) => {
                      props.setProgress(100);
                      // Handle any additional error logic here
                    });
                }}
              >
                <option value="" disabled selected>
                  Select your option
                </option>
                {subscriptionSuggestions?.map((item, index) => {
                  return (
                    <option key={index} value={item.accountId}>
                      {item.accountName.substring(
                        0,
                        item.accountName.length - 25
                      )}
                    </option>
                  );
                })}
              </select>
            </div>
          )}

        <div className="container2" style={{ marginTop: "30px" }}>
          <h5
            style={{
              display: "inline-block",
              paddingRight: "5px",
            }}
          >
            Create a new relationship
          </h5>
          <span
            data-tooltip-id="create"
            data-tooltip-content={
              isRetired
                ? "This application is retired"
                : !showOwner
                ? "Restricted: Application has not CI owner assigned"
                : relationshipCategorySelected.label === "Server to App"
                ? "Search for a server first"
                : "Search for an application first"
            }
            data-tooltip-hidden={
              (relationshipCategorySelected.value === "server_to_app"
                ? inputserverName
                : applicationName) == "" ||
              !showOwner ||
              isRetired
                ? false
                : true
            }
            data-tooltip-place="bottom-start"
            style={{
              cursor:
                isRetired ||
                (relationshipCategorySelected.value === "server_to_app"
                  ? inputserverName
                  : applicationName) === "" ||
                !showOwner
                  ? "not-allowed"
                  : "pointer",
            }}
          >
            <button
              type="button"
              onClick={showCreate}
              id="create-button"
              className="btn btn-success "
              disabled={!showOwner ? true : !showcreatebutton}
              style={{
                pointerEvents:
                  isRetired ||
                  !showOwner ||
                  (relationshipCategorySelected.value === "server_to_app"
                    ? inputserverName
                    : applicationName) == ""
                    ? "none"
                    : "auto",
              }}
            >
              Create
            </button>
            <Tooltip id="create" />
          </span>
          {
            <span
              data-tooltip-id="bulkupload"
              data-tooltip-content={
                relationshipCategorySelected.value == "app_to_server"
                  ? ""
                  : "This feature is only available for App to Server relationships with application name search"
              }
              data-tooltip-hidden={
                relationshipCategorySelected.value == "app_to_cloud" ||
                relationshipCategorySelected.value == "app_to_app" ||
                relationshipCategorySelected.value == "app_to_repository" ||
                /^[Aa]\d{2,}$/.test(applicationName)
                  ? false
                  : true
              }
              data-tooltip-place="bottom-start"
            >
              <button
                className="btn btn-success"
                style={{
                  backgroundColor: "#42B0D5",
                  borderColor: "#42B0D5",
                  color: "white",
                  marginLeft: "12px",
                  cursor:
                    relationshipCategorySelected.value == "app_to_cloud" ||
                    relationshipCategorySelected.value == "app_to_app" ||
                    relationshipCategorySelected.value == "app_to_repository" ||
                    /^[Aa]\d{2,}$/.test(applicationName)
                      ? "not-allowed"
                      : "pointer",
                }}
                onClick={(event) => {
                  if (
                    !(
                      relationshipCategorySelected.value == "app_to_cloud" ||
                      relationshipCategorySelected.value == "app_to_app" ||
                      relationshipCategorySelected.value == "app_to_repository" ||
                      /^[Aa]\d{2,}$/.test(applicationName)
                    )
                  ) {
                    props.setbulkuploadmodal(true);
                  }
                  event.preventDefault();
                }}
                disabled={
                  relationshipCategorySelected.value == "app_to_cloud" ||
                  relationshipCategorySelected.value == "app_to_app" ||
                  relationshipCategorySelected.value == "app_to_repository" ||
                  /^[Aa]\d{2,}$/.test(applicationName)
                    ? true
                    : false
                }
              >
                Bulk Upload
              </button>
              <Tooltip id="bulkupload" />
            </span>
          }
          {showSubscribe && !showUnsubscribe && (
            <div style={{ display: "inline-block" }}>
              <div
                style={{
                  marginLeft: "5px",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                {/* Subscribe Button */}
                <div
                  style={{
                    marginLeft: "0px",
                  }}
                >
                  <button
                    onClick={handleSubscribe}
                    className="btn btn-primary"
                    style={{
                      backgroundColor: "#42b0d5",
                      borderColor: "#42b0d5",
                      fontSize: "1rem",
                    }}
                  >
                    Subscribe
                  </button>
                </div>
              </div>
            </div>
          )}
          {showUnsubscribe && !showSubscribe && (
            <div style={{ display: "inline-block" }}>
              <div
                style={{
                  marginLeft: "5px",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                {/* Unsubscribe Button */}
                <div
                  style={{
                    marginLeft: "0px",
                  }}
                >
                  <button
                    onClick={handleUnsubscribe}
                    className="btn btn-primary"
                    style={{
                      backgroundColor: "#cf482e",
                      borderColor: "#cf482e",
                    }}
                  >
                    Unsubscribe
                  </button>
                </div>
              </div>
            </div>
          )}
          {showCertify && (
            <div style={{ display: "inline-block" }}>
              <div
                style={{
                  marginLeft: "15px",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  data-tooltip-id="certify-tooltip"
                  data-tooltip-content={
                    certificationSuccessful ? "" : "Approvals Pending"
                  }
                  data-tooltip-hidden={!disableCertify}
                  data-tooltip-place="bottom-start"
                  style={{
                    cursor: { disableCertify } ? "not-allowed" : "pointer",
                  }}
                >
                  <button
                    onClick={handlecertify}
                    disabled={disableCertify}
                    className="btn btn-success "
                    style={{
                      backgroundColor: "#42B0D5",
                      borderColor: "#42B0D5",
                    }}
                  >
                    Certify
                  </button>
                  {/* <Tooltip id="certify-tooltip" /> */}
                </div>
                <div
                  data-tooltip-id="info"
                  data-tooltip-content={showTooltip}
                  data-tooltip-place="bottom-start"
                  style={{
                    margin: "7px 0px 0px 10px",
                    display: "inline-block",
                  }}
                >
                  <Icon name="info-circle" size="24" />
                </div>
                <Tooltip id="info" />
              </div>
            </div>
          )}
          {showSaaS && (
            <div style={{ display: "inline-block" }}>
              <div
                style={{
                  marginLeft: "15px",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  data-tooltip-id="saas-tooltip"
                  data-tooltip-content={
                    certificationSuccessful ? "" : "Approvals Pending"
                  }
                  data-tooltip-place="bottom-start"
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <button
                    onClick={handleSaaSClick}
                    className="btn btn-success "
                    style={{
                      backgroundColor: !disableSaaS ? "#42B0D5" : "#205081",
                      borderColor: !disableSaaS ? "#42B0D5" : "#205081",
                    }}
                  >
                    {!disableSaaS ? 'Mark as SaaS' : 'Unmark as SaaS'}
                  </button>
                </div>
                <div
                  data-tooltip-id="info"
                  data-tooltip-content={showSaaSTooltip}
                  data-tooltip-place="bottom-start"
                  style={{
                    margin: "7px 0px 0px 10px",
                    display: "inline-block",
                  }}
                >
                  <Icon name="info-circle" size="24" />
                </div>
                <Tooltip id="info" />
              </div>
            </div>
          )}
        </div>
        <CommonModal
          isOpen={openserverenvmodal}
          onClose={() => setopenserverenvmodal(false)}
        >
          <ServerEnvironmentEditor
            servers={servers}
            setServers={setServers}
            onClose={() => setopenserverenvmodal(false)}
            applicationName={
              relationshipCategorySelected?.value === "server_to_app"
                ? applicationinputValue
                : /^[Aa]\d{2,}$/.test(applicationName)
                ? applicationNameSelected?.value
                : applicationName
            }
            applicationenv={applicationenv}
          />
        </CommonModal>
        {displaySuggestion && (
          <div className="relevant-box">
            {" "}
            Did you mean by:
            {suggestionList.map((item, index) => {
              return (
                <span
                  key={index}
                  className="badge"
                  onClick={() => handleSuggestionBadge({ item })}
                >
                  {item}
                </span>
              );
            })}
          </div>
        )}
      </div>
      {relationshipCategorySelected.value !== "app_to_repository" && (
        <div style={{ marginTop: "55px" }}>
          <Tabs
            selectedIndex={tabIndex}
            onSelect={handleTabClick}
            style={{ marginTop: "6%" }}
          >
            <div className="content" style={{ marginBottom: "-20px" }}>
              {(table1?.length > 0 ||
                data?.length > 0 ||
                table5?.length > 0 ||
                cloudData?.resourceGroups?.length > 0 ||
                appData?.length > 0 ||
                table8?.length > 0 ||
                serverData?.length > 0) && (
                <TabList>
                  <Tab>Table View</Tab>
                  <Tab
                    disabled={
                      relationshipCategorySelected.value === "app_to_server" ||
                      relationshipCategorySelected.value === "app_to_cloud" ||
                      relationshipCategorySelected.value === "server_to_app"
                    }
                  >
                    <span
                      data-tooltip-id="TreeView"
                      data-tooltip-content={`Tree view is not available for ${relationshipCategorySelected.label} relationship category`}
                      data-tooltip-hidden={
                        relationshipCategorySelected.value === "app_to_server" ||
                        relationshipCategorySelected.value === "server_to_app"
                          ? false
                          : true
                      }
                      data-tooltip-place="bottom-start"
                      style={{
                        cursor:
                          relationshipCategorySelected.value ===
                            "app_to_server" ||
                          relationshipCategorySelected.value === "app_to_cloud" ||
                          relationshipCategorySelected.value === "server_to_app"
                            ? "not-allowed"
                            : "pointer",
                      }}
                    >
                      Tree View
                      <Tooltip id="TreeView" />
                    </span>
                  </Tab>
                </TabList>
              )}
            </div>

            <TabPanel>
              {console.log(table1)}
              {relationshipCategorySelected.value === "app_to_server" &&
              (table1?.length > 0 || data?.length > 0) ? (
                <Table
                  Header1="App Name"
                  Header2={
                    /^[Aa]\d{2,}$/.test(applicationName)
                      ? "Environment"
                      : "Relationship Type"
                  }
                  Header3="Related To"
                  Header4="Server Type"
                  Header5="Server Env"
                  Header6="Server Status"
                  Header7="Server Role"
                  Header8="Server Vendor"
                  FilterIndex={3}
                  FilterIndex2={6}
                  setshowCommit={setshowCommit}
                  applicationId={applicationId}
                  applicationOwner={applicationOwner}
                />
              ) : (
                " "
              )}
              {relationshipCategorySelected.value === "app_to_app" &&
              (table5?.length > 0 || appData?.length > 0) ? (
                <Table5
                  Header1="CI Name"
                  Header2="Relationship Type"
                  Header3="Related To"
                  Header4="Type"
                  FilterIndex={1}
                  setshowCommit={setshowCommit}
                  applicationId={applicationId}
                  applicationOwner={applicationOwner}
                />
              ) : (
                " "
              )}

              {relationshipCategorySelected.value === "app_to_cloud" &&
              (cloudData?.resourceGroups?.length > 0 ||
                cloudData?.certified_data?.length > 0) ? (
                <CloudTableFormat
                  data1={cloudData}
                  setProgress={props.setProgress}
                  fetchCloudData={fetchCloudData}
                />
              ) : (
                " "
              )}

              {relationshipCategorySelected.value === "server_to_app" &&
              (table8?.length > 0 || serverData?.length > 0) ? (
                <Table8
                  Header1="Server Name"
                  Header2="Server Status"
                  Header3="Server Type"
                  Header4="Vendor"
                  Header5="Server Env"
                  Header6="Server Owner"
                  Header7="Server Role"
                  Header8="Related App"
                  Header9="App Owner"
                  FilterIndex={2}
                  FilterIndex2={7}
                  setshowCommit={setshowCommit}
                />
              ) : (
                " "
              )}
            </TabPanel>
            <TabPanel>
              <div
                className="content"
                style={{ height: "35vh", marginTop: "40px" }}
              >
                <Tree />
              </div>
            </TabPanel>
          </Tabs>
        </div>
      )}
      {relationshipCategorySelected.value === "app_to_repository" && (
        <div style={{ marginTop: "55px" }}>          
          <Tabs
            selectedIndex={tabIndex}
            onSelect={handleTabClick}
            style={{ marginTop: "6%" }}
          >
            <div
              className="content"
              style={{
                display: "grid",
                gridTemplateColumns: "1fr auto 1fr", // Three equal columns
                alignItems: "center",
                marginBottom: tabIndex === 1 ? "-20px" : "20px",
                width: "100%",
                transition: "none",
              }}
            >
              {(repoData?.length > 0 || repoSuggestions?.length > 0) && (
                <>
                  {/* Empty Placeholder for Left Space */}
                  <div></div>

                  {/* TabList Centered */}
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                   <TabList>
                      <Tab
                        disabled={repoData?.length === 0}
                      >
                        <span
                          data-tooltip-id="RelatedRepos"
                          data-tooltip-content={repoCiOwner ? 'No Related Repositories. Please create relationships first' : `No Related Repositories found`}
                          data-tooltip-hidden={
                            repoData?.length === 0
                              ? false
                              : true
                          }
                          data-tooltip-place="bottom-start"
                          style={{
                            cursor:
                              repoData?.length === 0
                                ? "not-allowed"
                                : "pointer",
                          }}
                        >
                          Related Repositories
                          <Tooltip id="RelatedRepos" />
                        </span>
                      </Tab>
                      <Tab>Suggested Repositories</Tab>
                    </TabList>
                  </div>

                  {/* Search Form Right-Aligned */}
                  { tabIndex === 1 && (
                    <div
                      className="search-form"
                      style={{
                        justifySelf: "end", // Align this to the right
                        marginRight: "-60px",
                      }}
                    >
                      <form onSubmit={handleRepoSearchSubmit} style={{ display: "inline-flex", gap: "10px" }}>
                        <label htmlFor="repository-name" style={{ fontSize: "1rem", marginTop: "5px" }}>Search for any Repository:</label>
                        <div
                          style={{
                            display: "inline-flex",
                            width: "300px",
                            flexDirection: "column",
                            position: "relative",
                          }}
                        >
                          <div style={{ position: "relative", width: "100%", height: "100%" }}>
                            <input
                              style={{
                                width: "100%",
                                height: "100%",
                                fontSize: "1rem",
                              }}
                              className="search-input"
                              type="text"
                              name="repositoryName"
                              id="repository-name"
                              onChange={handleSuggestedRepoNameChange}
                              value={repositoryName}
                              placeholder={"Search for any GitHub Repository"}
                              autoComplete="off"
                            />
                            {repositoryName && (
                              <FaTimes 
                                className="clear-icon" 
                                onClick={handleClearClick}
                                style={{ position: "absolute", right: "10px", top: "50%", transform: "translateY(-50%)", cursor: "pointer", color: "#888" }} 
                              />
                            )}
                          </div>
                          <div className="suggestion-container">
                            <div
                              style={{ width: "300px", position: "absolute", top: "100%", zIndex: "1000" }}
                              id="suggestion-box2"
                              className="suggestion-box"
                            ></div>
                          </div>
                        </div>
                        <input
                          type="submit"
                          style={{
                            backgroundColor: "#42B0D5",
                            fontSize: "1rem",
                          }}
                          value="Search"
                          ref={inputRef}
                        />
                      </form>
                    </div>
                  )}
                </>
              )}
            </div>

            <TabPanel>
              {(repoData?.length > 0) ? (
                <Table9
                  data9={repoData}
                  setProgress={props.setProgress}
                  fetchRepoData={fetchRepoData}
                />
              ) : (
                " "
              )}
            </TabPanel>
            <TabPanel>
                {(repoSuggestions.length > 0) ? (
                  <div>
                    <Table10
                      data10={repoSuggestions}
                      setProgress={props.setProgress}
                      fetchRepoData={fetchRepoData}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "40px",
                    }}
                  >
                    No Suggestions Available. Kindly search to find repositories.
                  </div>
                )}
            </TabPanel>
          </Tabs>
        </div>
      )}

      {relationshipCategorySelected.value == "app_to_server" && (
        <div
          className="commit-button"
          style={
            table1?.length === 0 && data?.length == 0
              ? { position: "absolute", top: "500px", right: "50px" }
              : {
                  position: "absolute",
                  marginTop: "10px",
                  right:
                    window.innerWidth -
                    tableRightBoundary -
                    (window.innerWidth - document.documentElement.scrollWidth),
                }
          }
        >
          {showCommit ? (
            <Button
              appearance="alt"
              fit="medium"
              id="primary"
              justifyItems="center"
              label="Confirm"
              name="primary"
              onClick={function noRefCheck() {
                handleCommit();
              }}
              title="Button"
              variant="filled"
            />
          ) : (
            " "
          )}
        </div>
      )}
      {relationshipCategorySelected.value == "app_to_app" && (
        <div
          className="commit-button"
          style={
            table5?.length === 0 && appData.length === 0
              ? { position: "absolute", top: "500px", right: "50px" }
              : {
                  position: "absolute",
                  marginTop: "10px",
                  right:
                    window.innerWidth -
                    tableRightBoundary -
                    (window.innerWidth - document.documentElement.scrollWidth),
                }
          }
        >
          {showCommit ? (
            <Button
              appearance="alt"
              fit="medium"
              id="primary"
              justifyItems="center"
              label="Confirm"
              name="primary"
              onClick={function noRefCheck() {
                handleCommit();
              }}
              title="Button"
              variant="filled"
            />
          ) : (
            " "
          )}
        </div>
      )}
      {relationshipCategorySelected.value == "server_to_app" && (
        <div
          className="commit-button"
          style={
            table8?.length === 0 && serverData?.length == 0
              ? { position: "absolute", top: "500px", right: "50px" }
              : {
                  position: "absolute",
                  marginTop: "10px",
                  right:
                    window.innerWidth -
                    tableRightBoundary -
                    (window.innerWidth - document.documentElement.scrollWidth),
                }
          }
        >
          {showCommit ? (
            <Button
              appearance="alt"
              fit="medium"
              id="primary"
              justifyItems="center"
              label="Confirm"
              name="primary"
              onClick={function noRefCheck() {
                handleCommit();
              }}
              title="Button"
              variant="filled"
            />
          ) : (
            " "
          )}
        </div>
      )}

      <div style={{ marginTop: "90px", visibility: "hidden" }}>""</div>
    </div>
  );
}
