import { Chip } from "@mui/material";
import React, { useState, useEffect } from "react";

const DynamicInput = ({
  type = "text",
  name = "",
  disabled = true,
  value = "",
  originaldata,
  onChange,
  options = {},
  suggestions = [],
  style = {},
   // Add a prop for the API endpoint
}) => {
  const [inputValue, setInputValue] = useState(value[name]?.displayValue);
  const [isTyping, setIsTyping] = useState(false);
  const [fetchedSuggestions, setFetchedSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [inputStyle, setInputStyle] = useState({});
  const [showCancelIcon, setShowCancelIcon] = useState(false);
  const apiEndpoint=`${process.env.REACT_APP_BACKEND_URI}/?applicationName=${encodeURIComponent(inputValue)}`

  useEffect(() => {
    setInputValue(value[name]?.displayValue);
         // Sync prop value with local state
  }, [value]);
  useEffect(() => {
    setIsTyping(false);
    setShowCancelIcon(false);
    setInputStyle({
      borderBottomLeftRadius: "8px",
      borderBottomRightRadius: "8px",
    }); // Sync prop value with local state
  }, [disabled]);

  useEffect(() => {
    if (inputValue && isTyping && type === "suggestions") {
      setIsLoading(true);
      const getApplicationSuggestion = setTimeout(async () => {
        try {
          const response = await fetch(
            apiEndpoint,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          const data = await response.json();
          setFetchedSuggestions(data);
        } catch (error) {
          console.error("Error fetching suggestions:", error);
        } finally {
          setIsLoading(false);
        }
      }, 500);
      return () => clearTimeout(getApplicationSuggestion);
    }
  }, [inputValue, isTyping, apiEndpoint]);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    setIsTyping(true);
    if (newValue.trim() === "") {
      setShowCancelIcon(false);
      
  } else {
      setShowCancelIcon(true);
     
  }
    if (type=="suggestions") {
    
    setInputStyle({
      borderBottomLeftRadius: "0px",
      borderBottomRightRadius: "0px",
    });
  }
    if (onChange)
      onChange({
        ...value,
        [e.target.name]: {
          displayValue: e.target.value, // Retain existing fields
          previousvalue: originaldata[e.target.name].displayValue,
          value: e.target.value,
        },
      }); // Propagate changes to parent
  };

  const handleSuggestionClick = (suggestion) => {
    setInputValue(suggestion);
    setIsTyping(false);
    setShowCancelIcon(false);
    setInputStyle({
      borderBottomLeftRadius: "8px",
      borderBottomRightRadius: "8px",
    });
    if (onChange)
      onChange({
        ...value,
        [name]: {
          displayValue: suggestion, // Retain existing fields
          previousvalue: originaldata[name].displayValue,
          value: suggestion,
        },
      });
  };

  const handleOptionClick = (e) => {
    setInputValue(e.target.value);
    setIsTyping(false);
    setShowCancelIcon(false);
    setInputStyle({
      borderBottomLeftRadius: "8px",
      borderBottomRightRadius: "8px",
    });
    if (onChange)
      onChange({
        ...value,
        [name]: {
          displayValue: e.target.value, // Retain existing fields
          previousvalue: originaldata[name]?.displayValue,
          value: options[e.target.value],
        },
      });
  };

  const handleCancelClick = () => {
    setInputValue(originaldata[name].displayValue);
    if (onChange)
      onChange({
        ...value,
        [name]: {
          displayValue: originaldata[name].displayValue, // Retain existing fields
          value: originaldata[name].value,
        },
      });

    setIsTyping(false);
    setShowCancelIcon(false);
    setFetchedSuggestions([]);
    setInputStyle({
      borderBottomLeftRadius: "8px",
      borderBottomRightRadius: "8px",
    });
  };

  const commonStyle = {
    width: "100%",
    height: "30px",
    boxSizing: "border-box",
    outline: "none",
    backgroundColor: disabled
    ? "rgba(211, 211, 211, 1)"
    : "white",
    ...style,
    ...inputStyle,
  };

  if (type === "textarea") {
    return (
      <div style={{ position: "relative", ...commonStyle, border: "none", padding: "0px" }}>
      <textarea
        value={inputValue}
        autoComplete="off"
        name={name}
        disabled={disabled}
        onChange={handleChange}
        style={{...commonStyle,height:"100px",width:"100%"}}
        />
        {showCancelIcon && (
          <span
            onClick={handleCancelClick}
            style={{
              position: "absolute",
              right: "10px",
              top: "50%",
              transform: "translateY(-50%)",
              cursor: "pointer",
              fontSize: "16px",
              color: "#999",
            }}
          >
            &#x2715;
          </span>
        )}
        </div>
      
    );
  }
                    
  if (type === "select") {
    const isValidOption = Object.keys(options).includes(inputValue);
    const displayValue = isValidOption ? inputValue : "";
  
    return (
      <select
        value={displayValue}
        name={name}
        disabled={disabled}
        onChange={handleOptionClick}
        style={commonStyle}
      >
        <option value="" disabled hidden></option>
        {Object.keys(options).map((optionKey) => (
          <option key={optionKey} value={optionKey}>
            {optionKey}
          </option>
        ))}
      </select>
    );
  }

  if (type === "suggestions") {
    return (
      <div style={{ position: "relative", ...commonStyle, border: "none", padding: "0px" }}>
        <input
          type="text"
          name={name}
          disabled={disabled}
          value={inputValue}
          onChange={handleChange}
          autoComplete="off"
          style={{
            ...commonStyle,
            height: "100%",
            width: "100%",
            padding: "0px",
            outline: "none",
          }}
        />
        {showCancelIcon && (
          <span
            onClick={handleCancelClick}
            style={{
              position: "absolute",
              right: "10px",
              top: "50%",
              transform: "translateY(-50%)",
              cursor: "pointer",
              fontSize: "16px",
              color: "#999",
            }}
          >
            &#x2715;
          </span>
        )}
        {isTyping && inputValue && (
          <ul
            className="search-input"
            style={{
              position: "absolute",
              top: "40%",
              left: 0,
              width: "100%",
              height: "fit-content",
              maxHeight: "80px",
              border: "1px solid #ccc",
              overflowY: "scroll",
              zIndex: 1000,
              listStyle: "none",
              backgroundColor: "#fff",
            }}
          >
            {isLoading ? (
              <li style={{ padding: "8px" }}>Loading...</li>
            ) : fetchedSuggestions.length > 0 ? (
              fetchedSuggestions
                .filter((s) => s.toLowerCase().includes(inputValue))
                .map((suggestion, idx) => (
                  <li
                    key={idx}
                    onClick={() => handleSuggestionClick(suggestion)}
                    style={{
                      padding: "8px",
                      cursor: "pointer",
                      borderBottom: "1px solid #eee",
                    }}
                  >
                    {suggestion}
                  </li>
                ))
            ) : (
              <li style={{ padding: "8px" }}>No results found</li>
            )}
          </ul>
        )}
      </div>
    );
  }

  return (
    <div style={{ position: "relative", ...commonStyle, border: "none", padding: "0px" }}>
    <input
      type="text"
      name={name}
      autoComplete="off"

      disabled={disabled}
      value={inputValue}
      onChange={handleChange}
      style={{...commonStyle,width:"100%"}}
      />
      {showCancelIcon &&(
          <span
            onClick={handleCancelClick}
            style={{
              position: "absolute",
              right: "10px",
              top: "50%",
              transform: "translateY(-50%)",
              cursor: "pointer",
              fontSize: "16px",
              color: "#999",
            }}
          >
            &#x2715;
          </span>
        )}
    {/* {name ==='name' && <Chip  label={name} color="primary" />} */}
      </div>
  );
};

export default DynamicInput;