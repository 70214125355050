import { Chip } from "@mui/material";
import React, { useState, useEffect } from "react";

    const DynamicInputAppName = ({
    type = "suggestions",
    name = "",
    disabled = false,
    value = "",
    originaldata,
    onChange,
    options = {},
    style = {},
    }) => {
  const [inputValue, setInputValue] = useState(value);
  const [isTyping, setIsTyping] = useState(false);
  const [fetchedSuggestions, setFetchedSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [inputStyle, setInputStyle] = useState({});
  const [showCancelIcon, setShowCancelIcon] = useState(false);
  const [showClearIcon, setShowClearIcon] = useState(false);
  const app_environment = value["u_environment"].displayValue;
  const [appSuffix, setAppSuffix] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(true);

  useEffect(() => {
    setInputValue(value[name].displayValue); // Sync prop value with local state
  }, [value]);
  useEffect(() => {
    setIsTyping(false);
    setInputValue(originaldata[name].displayValue);
    setShowClearIcon(false);

    setInputStyle({
      borderBottomLeftRadius: "8px",
      borderBottomRightRadius: "8px",
    }); // Sync prop value with local state
    if (!disabled) {
      setShowSuggestions(true);
    }
  }, [disabled]);

  useEffect(() => {
    if (inputValue && isTyping) {
      setIsLoading(true);
      if(app_environment === 'Development'){
        setAppSuffix(" - DEV");
      } else if(app_environment === 'Test'){
        setAppSuffix(" - TEST");
      } else if(app_environment === 'Pre-Production'){
        setAppSuffix(" - PP");
      } else if(app_environment === 'Education/Training'){
        setAppSuffix(" - EDU");
      }
      const suggestedname = inputValue + appSuffix;
      setFetchedSuggestions(suggestedname);
      setIsLoading(false);
    }
  }, [inputValue, isTyping]);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    setIsTyping(true);
    setShowCancelIcon(true);
    setShowClearIcon(true);
    setInputStyle({
      borderBottomLeftRadius: "0px",
      borderBottomRightRadius: "0px",
    });
    if (onChange)
      onChange({
        ...value,
        [e.target.name]: {
          displayValue: e.target.value, // Retain existing fields
          previousvalue: originaldata[e.target.name].displayValue,
          value: value[e.target.name].value,
        },
      }); // Propagate changes to parent
  };

  const handleSuggestionClick = (suggestion) => {
    setInputValue(suggestion);
    setIsTyping(false);
    setShowCancelIcon(false);
    // setShowClearIcon(false);
    setInputStyle({
      borderBottomLeftRadius: "8px",
      borderBottomRightRadius: "8px",
    });
    if (onChange)
      onChange({
        ...value,
        [name]: {
          displayValue: suggestion, // Retain existing fields
          previousvalue: originaldata[name].displayValue,
          value: suggestion.sys_id,
        },
      });
  };

  const handleOptionClick = (e) => {
    setInputValue(e.target.value);
    setIsTyping(false);
    setShowCancelIcon(false);
    setShowClearIcon(false);
    setInputStyle({
      borderBottomLeftRadius: "8px",
      borderBottomRightRadius: "8px",
    });
    if (onChange)
      onChange({
        ...value,
        [name]: {
          displayValue: e.target.value, // Retain existing fields
          previousvalue: originaldata[name].displayValue,
          value: options[e.target.value],
        },
      });
  };

  const handleCancelClick = () => {
    setShowSuggestions(false);
    setInputStyle({
      borderBottomLeftRadius: "8px",
      borderBottomRightRadius: "8px",
    });
  };

  const handleClearClick = () => {
    setInputValue(originaldata[name].displayValue);
    if (onChange)
      onChange({
        ...value,
        [name]: {
          displayValue: originaldata[name].displayValue, // Retain existing fields
          value: originaldata[name].value,
        },
      });

    setIsTyping(false);
    setShowClearIcon(false);
    setFetchedSuggestions([]);
    setInputStyle({
      borderBottomLeftRadius: "8px",
      borderBottomRightRadius: "8px",
    });
    setShowSuggestions(true);
  };

  const commonStyle = {
    width: "100%",
    height: "30px",
    boxSizing: "border-box",
    outline: "none",
    ...style,
    ...inputStyle,
  };

  if (type === "textarea") {
    return (
      <textarea
      autoComplete="off"

        value={inputValue}
        name={name}
        disabled={disabled}
        onChange={handleChange}
        style={{...commonStyle,height:"100px"}}
      />
    );
  }

  if (type === "select") {
    const isValidOption = Object.keys(options).includes(inputValue);
    const displayValue = isValidOption ? inputValue : "";
  
    return (
      <select
        value={displayValue}
        name={name}
        disabled={disabled}
        onChange={handleOptionClick}
        style={commonStyle}
      >
        <option value="" disabled hidden></option>
        {Object.keys(options).map((optionKey) => (
          <option key={optionKey} value={optionKey}>
            {optionKey}
          </option>
        ))}
      </select>
    );
  }

  if (type === "suggestions") {
    return (
      <div style={{ position: "relative", ...commonStyle, border: "none", padding: "0px" }}>
        <input
          type="text"
          autoComplete="off"

          name={name}
          disabled={disabled}
          value={inputValue}
          onChange={handleChange}
          style={{
            ...commonStyle,
            height: "100%",
            width: "100%",
            padding: "0px",
            outline: "none",
          }}
        />
        {showClearIcon && (
          <span
            onClick={handleClearClick}
            style={{
              position: "absolute",
              right: "10px",
              top: "50%",
              transform: "translateY(-50%)",
              cursor: "pointer",
              fontSize: "16px",
              color: "#999",
            }}
          >
            &#x2715;
          </span>
        )}
        
        {isTyping && inputValue && showSuggestions && (
          <ul
            className="search-input"
            style={{
              position: "absolute",
              top: "40%",
              left: 0,
              width: "100%",
              height: "fit-content",
              maxHeight: "80px",
              border: "1px solid #ccc",
              zIndex: 1000,
              listStyle: "none",
              backgroundColor: "#fff",
              borderBottomLeftRadius: "8px",
              borderBottomRightRadius: "8px",
            }}
          >
            {showCancelIcon && (
              <span
                onClick={handleCancelClick}
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "5px",
                  cursor: "pointer",
                  fontSize: "16px",
                  color: "#999",
                }}
              >
                &#x2715;
              </span>
            )}
            {isLoading ? (
              <li style={{ padding: "8px" }}>Loading...</li>
            ) : fetchedSuggestions.length > 0 ? (
                <li
                  onClick={() => handleSuggestionClick(fetchedSuggestions)}
                  style={{
                    padding: "8px",
                    cursor: "pointer",
                    borderBottom: "1px solid #eee",
                    fontSize: "0.9rem",
                  }}
                >
                  {'Suggested Name'}<br>
                  </br>
                  {fetchedSuggestions}
                </li>
            ) : (
              <li style={{ padding: "8px" }}>No suggestions available</li>
            )}
          </ul>
        )}
      </div>
    );
  }

  return (
    <>
    <input
      type="text"
      autoComplete="off"

      name={name}
      disabled={disabled}
      value={inputValue}
      onChange={handleChange}
      style={commonStyle}
      />
    {/* {name ==='name' && <Chip  label={name} color="primary" />} */}
      </>
  );
};

export default DynamicInputAppName;