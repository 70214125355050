import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CommonModal from "../CommonModal";

import ResourcePopUp from "./ResourcePopUp";

// CustomToolbar defined outside to prevent re-creation
const CustomToolbar = ({
  columns,
  selectedColumn,
  setSelectedColumn,
  operator,
  setOperator,
  onFilterApply,
}) => {
  const filterInputRef = useRef();

  return (
    <GridToolbarContainer>
      <GridToolbarExport
        printOptions={{ disableToolbarButton: true }}
        csvOptions={{ fileName: 'exported_data' }}
      />
      <GridToolbarFilterButton />
      <Box ml="auto">
        <GridToolbarQuickFilter />
      </Box>
    </GridToolbarContainer>
  );
};

function StorageData(props) {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [selectedColumn, setSelectedColumn] = useState("");
  const [operator, setOperator] = useState("contains");
  const [filterValue, setFilterValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
  const [openserverenvmodal, setopenserverenvmodal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 50,
    page: 0,
  });

  const handleFilter = useCallback(() => {
    let filtered = data;

    if (operator === "contains") {
      filtered = data?.filter((row) =>
        String(row[selectedColumn])
          .toLowerCase()
          .includes(filterValue.toLowerCase())
      );
    } else if (operator === "equals") {
      filtered = data?.filter((row) => String(row[selectedColumn]) === filterValue);
    }
    setFilteredData(filtered);
  }, [data, operator, selectedColumn, filterValue]);

  const handleFilterApply = useCallback(
    (newFilterValue) => {
      setFilterValue(newFilterValue);
      let filtered = data;

      if (operator === "contains") {
        filtered = data?.filter((row) =>
          String(row[selectedColumn])
            .toLowerCase()
            .includes(newFilterValue.toLowerCase())
        );
      } else if (operator === "equals") {
        filtered = data?.filter((row) => String(row[selectedColumn]) === newFilterValue);
      }

      setFilteredData(filtered);
    },
    [data, operator, selectedColumn]
  );

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URI}/dashboardData?function=storage_assets`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },      });
      const data = await response.json();
      setData(data?.data);
      setFilteredData(data?.data);
      const columns = Object.keys(data?.data[0] || {});
      const desiredOrder = [
        "Resource Name",
        "Resource Group Names",
        "Type",
        "Cloud Provider",
        "Subscription Names",
        "Application IDs",
        "Portfolio Names",
        "Platform Names",
        "Account ID",
        "Region ID",
        "Region Name",
        "Resource ID",
        "Admiral Product ID",
      ]; // Replace with your desired column names
      // Reorder the columns array based on the desired order
      const orderedColumns = desiredOrder.filter((column) =>
        columns.includes(column)
      );
      setColumns(orderedColumns);
      setSelectedColumn(orderedColumns[0] || "");
    };
    fetchData();
  }, []);

  function getRowId(row) {
    return row['Resource ID'];
  }

  const handleSelectionChange = (newSelection) => {
    console.log("Selected Rows Data:", newSelection);
  };

  const handleLinkClick = (row) => {
    console.log("Row Data:", row);
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "90vh", overflow: "auto" }}>
      <CommonModal
        isOpen={openserverenvmodal}
        onClose={() => setopenserverenvmodal(false)}
      >
        <ResourcePopUp rowData={selectedRowData} />
      </CommonModal>
      <Box sx={{ display: "flex", flexDirection: "column", height: "90%", width: "90%", minHeight: 300 }}>
        <Typography variant="h5" component="div" sx={{ mb: 2 }}>
          Storage
        </Typography>
        <DataGrid
          sx={{ fontSize: "0.8em" }}
          loading={data?.length === 0}
          getRowId={getRowId}
          handleSelectionChange={handleSelectionChange}
          columns={columns
            ?.filter((column) => column !== "id")
            .map((column, index) => ({
              field: column,
              flex: 1,
              // disableColumnMenu: true,
              renderCell: (params) => {
                  if (Array.isArray(params.value)) {
                    return params.value.join(", ");
                  }
                if (index === 0) {
                  return (
                    <button
                      style={{ color: "#42b0d5", cursor: "pointer", background: "none", border: "none", padding: 0 }}
                      onClick={(e) => {
                        setopenserverenvmodal(true);
                        setSelectedRowData({
                          "Account ID": params.row["Account ID"],
                          "Resource Name": params.row["Resource Name"],
                        });
                        console.log("Row Data:", params.row);
                      }}
                    >
                      {params.value}
                    </button>
                  );
                }
                return params.value;
              },
            }))}
          rows={filteredData}
          checkboxSelection
          density="standard"
          slots={{
            toolbar: () => (
              <CustomToolbar
                columns={columns}
                selectedColumn={selectedColumn}
                setSelectedColumn={setSelectedColumn}
                operator={operator}
                setOperator={setOperator}
                onFilterApply={handleFilterApply}
              />
            ),
          }}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
            console.log("Selected Rows:", newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
          autosizeOnMount={true}
          disableRowSelectionOnClick
        />
      </Box>
    </Box>
  );
}

export default StorageData;