import React, { useState, useLayoutEffect, useContext,useEffect,useRef } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import Dashboard from "./Dashboard";
import KubernetesTable from "./AssetExplorer/KubernetesTable";
import DatabaseTable from "./AssetExplorer/DatabaseTable";
import AliBabaData from "./AssetExplorer/AliBabaData";
import OnPremise from "./AssetExplorer/OnPremise";
import GithubData from "./AssetExplorer/GithubData";
import IdentityServices from "./AssetExplorer/IdentityServices";
import Networks from "./AssetExplorer/Networks";
import Compute from "./AssetExplorer/Compute";
import AppService from "./AssetExplorer/AppService";
import StorageData from "./AssetExplorer/StorageData";
import { TableContext } from "../context";
import { AppBar, Toolbar, Typography, Container, Grid, Card, CardContent, Box, useMediaQuery, CircularProgress } from '@mui/material';
import { toast } from "react-toastify";
import AllResourceData from "./AssetExplorer/AllResourceData";


function Database() {
  return <div>Database Content</div>;
}

function Kubernetes() {
  return <div>Kubernetes Content</div>;
}

function Servers() {
  return <div>Servers Content</div>;
}

function AzureFunctions() {
  return <div>Azure Functions Content</div>;
}

function Github() {
  return <div>Github Content</div>;
}

function AssetExplorer() {
  const [content, setContent] = useState("main");
  const isLargeScreen = useMediaQuery('(max-width:1500px)');

  const [activeItem, setActiveItem] = useState("main");

  const [dashboardData, setDashboardData] = useState([]);
  const [loading, setLoading] = useState(false);
  const {fetchWithTimeout,graphData}=useContext(TableContext);


  useEffect(() => {
    localStorage.removeItem('serverTabId');
   }, []);

  
  useEffect(() => {
  
    const fetchData = async () => {

  
      setLoading(true);
      const response = await fetchWithTimeout(`${process.env.REACT_APP_BACKEND_URI}/dashboardData?function=get_counts`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const data = await response.json();
      setDashboardData(data.data);
      setLoading(false);
    };
  
    if(graphData?.displayName !==undefined)
      {
        toast.promise(fetchData(), {
        pending: "Fetching Data",
        success: "Data Fetched",
        error: "Error Fetching Data",
      });
      }
  }, [graphData?.displayName]);

  const renderContent = () => {
    switch (content) {
      case "Database":
        return <DatabaseTable />;
      case "Servers":
        return <Servers />;
      case "App Service":
        return <AppService />;
      case "Azure Functions":
        return <AzureFunctions />;
      case "Github":
        return <GithubData />;
      case "Ali Cloud":
        return <AliBabaData />;
      case "On Premise":
        return <OnPremise />;
      case "Identity Service":
        return <IdentityServices />;
      case "Networks":
        return <Networks />;
      case "Compute":
        return <Compute />;
      case "Kubernetes":
        return <KubernetesTable />;
      case "Storage":
        return <StorageData />;
        case "Cloud":
        return <AllResourceData />;
      default:
        return <Dashboard setContent={setContent} loading={loading} dashboardData={dashboardData} />;
    }
  };

  const handleMenuItemClick = (item) => {
    setContent(item);
    setActiveItem(item);
  };
  return (
    <div
      style={{
        display: "flex",
        // minHeight: "100%",
        marginTop: "50px",
        position: "fixed",
        width: "100%",
        height:isLargeScreen?"85vh":"calc(85vh + 10vh)",
      }}
    >
      <Sidebar
        defaultCollapsed={true}
        closeOnClick={true}
        toggled={true}
        rootStyles={{
          backgroundColor: "rgb(66, 176, 213)",
        }}
      >
        <Menu>
          <MenuItem
            onClick={() => handleMenuItemClick("main")}
            active={activeItem === "main"}
          >
            Dashboard
          </MenuItem>
          <SubMenu label="Infrastructure">
            <SubMenu label="Cloud"  onClick={() => handleMenuItemClick("Cloud")}>
              <MenuItem
                onClick={() => handleMenuItemClick("Compute")}
                active={activeItem === "Compute"}
              >
                Compute
              </MenuItem>
              <MenuItem
                onClick={() => handleMenuItemClick("Kubernetes")}
                active={activeItem === "Kubernetes"}
              >
                Kubernetes
              </MenuItem>
              <MenuItem
                onClick={() => handleMenuItemClick("Identity Service")}
                active={activeItem === "Identity Service"}
              >
                Identity Service
              </MenuItem>
              <MenuItem
                onClick={() => handleMenuItemClick("Database")}
                active={activeItem === "Database"}
              >
                Database
              </MenuItem>
              <MenuItem
                onClick={() => handleMenuItemClick("Networks")}
                active={activeItem === "Networks"}
              >
                Networks
              </MenuItem>
              <MenuItem
                onClick={() => handleMenuItemClick("Storage")}
                active={activeItem === "Storage"}
              >
                Storage
              </MenuItem>
              <MenuItem
                onClick={() => handleMenuItemClick("App Service")}
                active={activeItem === "App Service"}
              >
                App Service
              </MenuItem>
              <MenuItem
                onClick={() => handleMenuItemClick("Ali Cloud")}
                active={activeItem === "Ali Cloud"}
              >
                Ali Cloud
              </MenuItem>
            </SubMenu>
            <MenuItem
              onClick={() => handleMenuItemClick("On Premise")}
              active={true}
            >
              Os Query Servers
            </MenuItem>
          </SubMenu>
          <SubMenu label="Code Repos">
            <MenuItem
              onClick={() => handleMenuItemClick("Github")}
              active={activeItem === "Github"}
            >
              Github
            </MenuItem>
          </SubMenu>
        </Menu>
      </Sidebar>
      <main style={{ overflow: "scroll", width: "100%" }}>
        {renderContent()}
      </main>
    </div>
  );
}

export default AssetExplorer;