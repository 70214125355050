import { React, useEffect, useState, useLayoutEffect } from 'react';
import { AppBar, Toolbar, Typography, Container, Grid, Card, CardContent, Box, useMediaQuery, CircularProgress } from '@mui/material';
import { useSpring, animated } from "@react-spring/web";
import { PieChart } from '@mui/x-charts/PieChart';

const initialData = [
  { name: 'Azure', value: 400 },
  { name: 'Ali Baba', value: 300 },
  { name: 'Kubernetes', value: 300 },
  { name: 'Database', value: 200 },
];

const items = [
  { id: 0, value: 2432, label: 'Azure' },
  { id: 1, value: 2432, label: 'Ali Baba' },
  { id: 2, value: 2432, label: 'Database' },
];

const COLORS = ['#42B0D5', '#00243D', '#0073AB', '#B5E0F5','#FA6A55','#7C9D00','#963700','#004D40','#FA381C','#A3BF00','#F07508','#00786B'];

const Dashboard = ({setContent,loading,dashboardData}) => {

  const isLargeScreen = useMediaQuery('(min-width:1400px)');
  const outerRadius = isLargeScreen ? 100 : 70;
  const cx = isLargeScreen ? "57%" : "67%"; // Adjust these values as needed
  const cy = isLargeScreen ? 190 : 180;
  const fontSize = isLargeScreen ? 14 : 10;
  const getTop5Data = (data) => {
    const sortedData = [...data]?.sort((a, b) => b.value - a.value);
    console.log(sortedData);
    const top5 = sortedData.slice(0, 5);
    const othersValue = sortedData.slice(5).reduce((acc, item) => acc + item.value, 0);
    if (othersValue > 0) {
      top5.push({ label: 'Others', value: othersValue });
    }
    return top5;
   };
   const pieParams = {
    slotProps: { legend: { hidden: true, } },
   };

    const RenderCard = (label, value,data,count) => {
    const { number } = useSpring({
      from: { number: 0 },
      to: { number: loading ? 0 : Number(count) },
      config: { duration: 1500 },
    });
  
    return (
      <Grid item xs={12} sm={6} md={6} lg={4}>
        <Box
          sx={{
            display: 'flex',
            maxWidth: "700px",
            flexDirection: 'column',
            boxShadow: 3,
            borderRadius: 2,
            p: 2,
            height: "400px",
            transition: 'transform 0.3s, box-shadow 0.3s',
            '&:hover': {
              transform: 'translateY(-5px)',
              boxShadow: 6,
            },
          }}
        >
          <PieChart
            colors={COLORS}
            loading={loading}
            series={[
              {
                innerRadius: 5,
                outerRadius: outerRadius,
                paddingAngle: 1,
                cornerRadius: 1,
                cx: cx,
                cy: cy,
                data: getTop5Data(data ?? []),
                highlightScope: { fade: 'global', highlight: 'item' },
                faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
              },
            ]}
            slotProps={{
              legend: {
                direction: 'row',
                position: { vertical: 'top', horizontal: 'left' },
                padding: 0,
                labelStyle: {
                  fontSize: fontSize,
                },
              },
            }}
          />
          <Grid item xs={3} sx={{ marginRight: '0px', padding: 'none' }}>
            <Typography variant="h6" gutterBottom sx={{width:"200px"}}>
              {label}
            </Typography>
            <Typography variant="h5" color="primary">
              <animated.div>
                {number.to((n) => Math.floor(n).toLocaleString())}
              </animated.div>
            </Typography>
          </Grid>
        </Box>
      </Grid>
    );
  };

  return (
    <div>
      <div>
        <Typography variant="h4" gutterBottom sx={{ margin: "20px",}}>
          Dashboard
        </Typography>

        {false ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={3} sx={{ overflowY: "scroll", height: "77vh",padding:"0 20px" }}>
            {dashboardData  && (
              <>
                {RenderCard('Azure', 400,dashboardData?.azure_asset_type_counts,dashboardData?.azure_count?.value)}
                {RenderCard('Ali Baba', 400,dashboardData?.alicloud_asset_type_counts,dashboardData?.alicloud_count?.value)}
                {RenderCard('Os Query Servers', 400,dashboardData?.onpremise_platform_counts,dashboardData?.onpremise_count?.value)}
                {RenderCard('Github', 400,dashboardData?.github_mapped_count,dashboardData?.github_count?.value)}

              
              </>
           )}
          </Grid>
        )}
      </div>
    </div>
  );
};

export default Dashboard;