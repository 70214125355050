import React from 'react'
import ConstructionIcon from '@mui/icons-material/Construction';
import { Box, Typography } from '@mui/material';






  
function SiteUnderMaintainance() {
  return (
    <div>
        <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      textAlign="center"
      bgcolor="#f5f5f5"
    >
      <ConstructionIcon style={{ fontSize: 130, color: '#87ceeb' }} />
      <Typography variant="h4" style={{ marginTop: 20, color: '#333' }}>
        Our site is under maintainance!
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 10, color: '#666' }}>
      We are currently in the process of deploying new features in MSA.<br>
      </br>The functionality may be restricted, you can come back later today to view your data
      </Typography>
    </Box>
    </div>
  )
}

export default SiteUnderMaintainance