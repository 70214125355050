import * as React from "react";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";

export default function ResourceTagInput({
  type = "text",
  name = "",
  disabled = false,
  value = "",
  originaldata,
  onChange,
  options = {},
  suggestions = [],
  style = {},
  selectedItems,
  setSelectedItems
  // Add a prop for the API endpoint
}) {
  const [inputValue, setInputValue] = useState("");
  const [fetchedSuggestions, setFetchedSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [inputStyle, setInputStyle] = useState({});
  const [showCancelIcon, setShowCancelIcon] = useState(false);
  const apiEndpoint = `${
    process.env.REACT_APP_BACKEND_URI
  }/getappsforcertify?applicationName=${encodeURIComponent(inputValue)}`;
  const [isTyping, setIsTyping] = useState(false);

  //   useEffect(() => {
  //     setInputValue(value[name].displayValue); // Sync prop value with local state
  //   }, [value]);

//   useEffect(() => {
//     setIsTyping(false);
//     setSelectedItems([]);
//     setInputValue(originaldata[name].displayValue);
//     setInputStyle({
//       borderBottomLeftRadius: "8px",
//       borderBottomRightRadius: "8px",
//     }); // Sync prop value with local state
//   }, [disabled]);
  useEffect(() => {
    if (inputValue && isTyping) {
      setIsLoading(true);
      const getApplicationSuggestion = setTimeout(async () => {
        try {
          const response = await fetch(apiEndpoint, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });
          const data = await response.json();
          const filteredData = data.filter((suggestion) => suggestion !== "");
          setFetchedSuggestions(filteredData);
        } catch (error) {
          console.error("Error fetching suggestions:", error);
        } finally {
          setIsLoading(false);
        }
      }, 500);
      return () => clearTimeout(getApplicationSuggestion);
    }
  }, [inputValue, isTyping, apiEndpoint]);

  // Handle input change
  const handleInputChange = (e) => {
    setIsTyping(true);
    setShowCancelIcon(true);
    const value = e.target.value;
    setInputValue(value);
    setInputStyle({
      borderBottomLeftRadius: "0px",
      borderBottomRightRadius: "0px",
    });
    if (value) {
    } else {
      setFetchedSuggestions([]);
    }
  };

  const handleCancelClick = () => {
    setInputValue("");

    setIsTyping(false);
    setShowCancelIcon(false);
    setFetchedSuggestions([]);
    setInputStyle({
      borderBottomLeftRadius: "8px",
      borderBottomRightRadius: "8px",
    });
  };

  const commonStyle = {
    width: "100%",
    height: "30px",
    boxSizing: "border-box",
    outline: "none",
    ...style,
    ...inputStyle,
  };

  // Handle item selection
  const handleSelect = (item) => {
    // Check if the item is already in selectedItems based on displayValue
    const isDuplicate = selectedItems.some(
      (selected) => selected === item
    );
  
    if (!isDuplicate ) {
      const newSelectedItems = [...selectedItems, item];
      setSelectedItems(newSelectedItems);
      setShowCancelIcon(false);
      setInputStyle({
        borderBottomLeftRadius: "8px",
        borderBottomRightRadius: "8px",
      });
      console.log(newSelectedItems);
  
    //   if (onChange) {
    //     onChange({
    //       ...value,
    //       [name]: {
    //         displayValue:  newSelectedItems
    //           .map((selected) => selected.name)
    //           .join(", "), // Append selectedItems.name to displayValue with a comma
    //         previousvalue: originaldata[name].displayValue,
    //         value: newSelectedItems
    //           .map((selected) => selected.sys_id)
    //           .join(", "), // Append suggestedItems.sys_id to value with a comma
    //       },
    //     });
    //   }
    } else {
      toast.info("Already selected", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setInputStyle({
        borderBottomLeftRadius: "8px",
        borderBottomRightRadius: "8px",
      });
    }
  
    setInputValue(""); // Clear input after selection
    setFetchedSuggestions([]); // Clear suggestions after selection
  };

  // Handle removing selected items
  const handleRemove = (item) => {
    const updatedSelectedItems = selectedItems.filter(
      (selected) => selected !== item
    );
    setSelectedItems(updatedSelectedItems);
    


    if (onChange) {
      const updatedDisplayValue = updatedSelectedItems
        .map((selected) => selected)
        .join(", ");
      const updatedValue = updatedSelectedItems
        .map((selected) => selected)
        .join(", ");
    //   onChange({
    //     ...value,
    //     [name]: {
    //       displayValue: updatedDisplayValue,
    //       previousvalue: originaldata[name].displayValue,
    //       value: updatedValue,
    //     },
    //   });
    }
  };
  console.log(selectedItems);

  return (
       <div className="multi-select-container" style={{ width: "60%" }}>
      <div className="input-wrapper" style={{ position: "relative" }}>
        {/* Render selected items */}
        <div
          className="selected-items"
          style={{ display: "flex", flexWrap: "wrap", gap: "5px",marginBottom:"2px" }}
         >
          {selectedItems.map((item, index) => (
            <div
              key={index}
              className="selected-item"
              style={{
                background: "#0073AB",
                fontSize: "0.7rem",
                color: "white",
                padding: "5px 10px",
                borderRadius: "20px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {item}
              <span
                onClick={() => handleRemove(item)}
                style={{
                  marginLeft: "8px",
                  cursor: "pointer",
                  fontWeight: "bold",
                }}
              >
                x
              </span>
            </div>
          ))}
        </div>
        {/* Input for new entries */}
        <div style={{ width: "100%", position: "relative" }}>
          <input
            type="textarea"
            value={inputValue}
            onChange={handleInputChange}
            disabled={disabled}
            placeholder= {!disabled? "Type to search...": ""}
            style={{ ...commonStyle, width: "100%" }}
          />
          {showCancelIcon && (
            <span
              onClick={handleCancelClick}
              style={{
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                cursor: "pointer",
                fontSize: "16px",
                color: "#999",
              }}
            >
              &#x2715;
            </span>
          )}
        </div>
    
        {/* Suggestions dropdown */}
        {isTyping && inputValue && (
          <ul
            className="suggestions"
            style={{
              listStyleType: "none",
              margin: 0,
              padding: "5px",
              border: "1px solid #ccc",
              borderTop: "none",
              borderRadius: "4px",
              borderTopLeftRadius: "0px",
              borderTopRightRadius: "0px",
              height: "fit-content",
              maxHeight: "80px",
              overflowY: "scroll",
              position: "absolute",
              zIndex: 1000,
              background: "white",
              width: "100%",
            }}
          >
            {isLoading ? (
              <li style={{ padding: "8px" }}>Loading...</li>
            ) : fetchedSuggestions.length > 0 ? (
              fetchedSuggestions?.filter((s) => s?.toLowerCase().includes(inputValue))
              .map((item, index) => (
                <li
                  key={index}
                  onClick={() => handleSelect(item)}
                  style={{
                    padding: "8px",
                    cursor: "pointer",
                    fontSize: "0.8rem",
                  }}
                >
                  {item}
                </li>
              ))
            ) : (
              <li style={{ padding: "8px" }}>No results found</li>
            )}
          </ul>
        )}
      </div>
    </div>
  );
}
